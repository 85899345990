import React from "react";
import { ResetPasswordForm } from "./ResetPasswordForm";
import { Redirect, withRouter } from "react-router-dom";

const AuthActionFormComponent = ({ location }) => {
  let mode = new URLSearchParams(location.search).get("mode");

  if (mode === "resetPassword") {
    return <ResetPasswordForm />
  }

  return <Redirect to="/auth/login" />
};

export const AuthActionForm = withRouter(AuthActionFormComponent);
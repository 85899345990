import { createAction } from "@reduxjs/toolkit";
import { OAuthUser } from "services/AuthService";
import { UserCompany } from "models/UserCompany";

export interface SessionState {
  user?: OAuthUser
  isLogged: boolean
}

const initialState: SessionState = {
  user: null,
  isLogged: false,
}

export interface SignInSuccessAction extends AlertAction {
  user: OAuthUser
}

export interface CompanySelected {
  company: UserCompany
}

interface AuthStateChangedAction {
  user: OAuthUser | null
}

interface ResetPasswordSuccessAction extends AlertAction {
}

interface ConfirmPasswordSuccessAction extends AlertAction {
}

interface AlertAction {
  message: String
}

export const SIGN_IN_SUCCESS = createAction<SignInSuccessAction>('session/sign_in_success');
export const SIGN_IN_ERROR = createAction<AlertAction>('session/sign_in_error');
export const SIGN_UP_SUCCESS = createAction<SignInSuccessAction>('session/sign_up_success');
export const SIGN_UP_ERROR = createAction<AlertAction>('session/sign_up_error');
export const FORGOT_PASSWORD_SUCCESS = createAction<ResetPasswordSuccessAction>('session/reset_password_success');
export const FORGOT_PASSWORD_ERROR = createAction<AlertAction>('session/reset_password_error');
export const RESET_PASSWORD_SUCCESS = createAction<ConfirmPasswordSuccessAction>('session/confirm_password_success');
export const RESET_PASSWORD_ERROR = createAction<AlertAction>('session/confirm_password_error');
export const LOGOUT_SUCCESS = createAction<AlertAction>('session/logout_success');
export const LOGOUT_ERROR = createAction<AlertAction>('session/logout_error');
export const AUTH_STATE_CHANGED_SUCCESS = createAction<AuthStateChangedAction>('session/auth_state_changed_success');
export const AUTH_STATE_CHANGE_ERROR = createAction<AlertAction>('session/auth_state_change_error');
export const COMPANY_SELECTED = createAction<CompanySelected>('session/company_selected');

// Use the initialState as a default value
export function sessionReducer(state: SessionState = initialState, action: any) {
  // The reducer normally looks at the action type field to decide what happens
  switch (action.type) {
    case LOGOUT_SUCCESS.type:
      return {
        isLogged: false
      }
    case SIGN_IN_SUCCESS.type:
      return {
        ...state,
        user: action.payload.user,
        isLogged: true
      }
    case AUTH_STATE_CHANGED_SUCCESS.type:
      return {
        ...state,
        user: action.payload.user,
        isLogged: !!action.payload.user
      }
    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state
  }
}
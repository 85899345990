import { combineReducers } from "redux";
import { sessionReducer } from "./session";
import { notificationReducer } from "./notification";
import { accountReducer } from "./account";
import { trackingReducer } from "./tracking";
import { companyReducer } from "./company";
import { customerReducer } from "reducers/customer";

export default combineReducers({
  session: sessionReducer,
  notification: notificationReducer,
  account: accountReducer,
  tracking: trackingReducer,
  company: companyReducer,
  customer: customerReducer
});
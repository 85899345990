import configureStore from "utils/configureStore";
import { SessionState } from "reducers/session";
import { NotificationState } from "reducers/notification";
import { TrackingState } from "reducers/tracking";
import { CompanyState } from "reducers/company";
import { CustomerState } from "reducers/customer";

export interface GlobalState {
  session: SessionState
  notification: NotificationState
  tracking: TrackingState
  company: CompanyState
  customer: CustomerState
}

export const store = configureStore();
import React from "react";
import { TSignInForm } from "stories/components/forms/TSignInForm";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SIGN_IN_ERROR, SIGN_IN_SUCCESS } from "reducers/session";
import { AuthService, FirebaseService, OAuthUser } from "services/AuthService";
import { CompanyRemoteService, CompanyService, CompanySort } from "services/CompanyService";
import { AuthHttpClient, HttpClientOptionsDefaults } from "services/HttpClient";
import * as H from "history";
import { UserCompany } from "models/UserCompany";
import { useTranslation } from "react-i18next";

const defaultAuthHttpClient = new AuthHttpClient(HttpClientOptionsDefaults, FirebaseService.getInstance());

const SignInFormComponent: React.FunctionComponent<SignInFormProps> = ({ authService = FirebaseService.getInstance(),
                               companyService = new CompanyRemoteService(defaultAuthHttpClient),
                               history }: SignInFormProps) => {
  let dispatch = useDispatch();
  const { t } = useTranslation();

  const onSignInByEmail = (email: string, password: string) => {
    return (resolve: Function, reject: Function) => {
      authService.signInWithEmailAndPassword(email, password)
        .then((user) => {
          companyService.getUserCompanies(CompanySort.DEFAULT_DESC)
            .then((companies) => {
              const defaultCompany: UserCompany = companies.filter(c => c.default)[0] || null;
              onUserSignInSuccess(user, defaultCompany);
              resolve();
            })
        })
        .catch((error) => {
          dispatch(SIGN_IN_ERROR(error))
          reject(error);
        });
    };
  };

  const onSignUp = () => {
    history.push("/auth/signup");
  };

  const onResetPassword = () => {
    history.push("/auth/forgot");
  };

  const onGoogleSignIn = () => {
    authService.signInWithGoogle()
      .then((user) => {
        onUserSignInSuccess(user);
      })
      .catch((error) => {
        dispatch(SIGN_IN_ERROR(error))
      });
  };

  const onFacebookSignIn = () => {
    authService.signInWithFacebook()
      .then((user) => {
        onUserSignInSuccess(user);
      })
      .catch((error) => {
        dispatch(SIGN_IN_ERROR(error))
      });
  };

  const onUserSignInSuccess = (user: OAuthUser, defaultCompany?: UserCompany) => {
    dispatch(SIGN_IN_SUCCESS({
      user: user,
      message: t("Signed in successfully")
    }));

    if (defaultCompany === null) {
      history.push("/");
      return;
    }

    history.push(`/companies/${defaultCompany.id}/`);
  }

  return (
    <TSignInForm
      onSignInByEmail={onSignInByEmail}
      onGoogleSignIn={onGoogleSignIn}
      onFacebookSignIn={onFacebookSignIn}
      onResetPassword={onResetPassword}
      onSignUp={onSignUp}/>
  );
};

export const SignInForm = withRouter(SignInFormComponent);

export interface SignInFormProps extends RouteComponentProps<any> {
  authService: AuthService
  companyService: CompanyService
  history: H.History
}
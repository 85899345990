import { makeStyles } from "@material-ui/styles";
import { Container, Theme } from "@material-ui/core";
import { CustomerRemoteService, CustomerService } from "services/CustomerService";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import React, { useState } from "react";
import { Customer, CustomerLog, CustomerLogType } from "models/Customer";
import { CUSTOMER_LOG_CREATE_ERROR, CUSTOMER_LOG_CREATED_SUCCESS } from "reducers/customer";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { AddComment, AddIcCall } from "@material-ui/icons";
import { CustomerLogsTable } from "components/tables/CustomerLogsTable";
import { RouteComponentProps } from "react-router";
import { AuthHttpClient, HttpClientOptionsDefaults } from "services/HttpClient";
import { FirebaseService } from "services/AuthService";
import { CustomerLogDialog, CustomerLogFormFields } from "pages/CustomerProfilePage/components/CustomerLogDialog";

const useLogsStyles = makeStyles((theme: Theme) => ({
  form: {
    padding: theme.spacing(2, 0, 2)
  }
}));

export interface CustomerLogsTabProps extends Partial<RouteComponentProps> {
  customer: Customer
  customerService?: CustomerService
}

const defaultAuthHttpClient = new AuthHttpClient(HttpClientOptionsDefaults, FirebaseService.getInstance());

export const CustomerLogsTab = ({ customer, customerService = new CustomerRemoteService(defaultAuthHttpClient) }: CustomerLogsTabProps) => {
  const { t } = useTranslation();
  const classes = useLogsStyles();
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editLog, setEditLog] = useState<CustomerLog | null>(null);

  const onCreateMenuClickHandler = (logType: string) => {
    setEditLog({
      type: logType
    } as CustomerLog);
    setDialogOpen(true);
  };

  const onEditClickHandler = (log: CustomerLog) => {
    setEditLog(log);
    setDialogOpen(true);
  };

  const onSaveHandler = (form: CustomerLogFormFields): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      const log = {
        ...form
      } as CustomerLog;

      let logPromise: Promise<CustomerLog> = log?.id ?
        customerService.editLog(log) :
        customerService.createLog(customer.id, log);

      logPromise
        .then((log: CustomerLog) => {
          dispatch(CUSTOMER_LOG_CREATED_SUCCESS({
            log: log,
            message: t("Customer log added")
          }));
          resolve();
          setDialogOpen(false);
          setEditLog(null);
        })
        .catch((error) => {
          dispatch(CUSTOMER_LOG_CREATE_ERROR(error));
          reject(error);
        });
    });
  };

  const onDialogCloseHandler = () => {
    setDialogOpen(false);
  };

  return (
    <Container>
      <Grid container spacing={2} className={classes.form}>
        <Grid container item xs={12} alignItems="center" justify="flex-end">
          <Tooltip title={t("Add {entity}", { entity: t("Note") })}>
            <IconButton color="primary" onClick={() => onCreateMenuClickHandler(CustomerLogType.NOTE)}>
              <AddComment/>
            </IconButton>
          </Tooltip>
          <Tooltip title={t("Add {entity}", { entity: t("Phone call") })}>
            <IconButton color="primary" onClick={() => onCreateMenuClickHandler(CustomerLogType.PHONE_CALL)}>
              <AddIcCall/>
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <CustomerLogsTable customerId={customer.id} onEdit={onEditClickHandler}/>
      <CustomerLogDialog open={dialogOpen} log={editLog} onClose={onDialogCloseHandler} onSave={onSaveHandler}/>
    </Container>
  );
};
import React from "react";
import { BrowserRouter as Router, Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import { connect } from "react-redux";
import routes, { RouteComponent } from "./routes";
import { GlobalState } from "store";
import { AlertManager } from "components/AlertManager";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const AppComponent = ({ isLogged }: AppProps) => {
  const { t } = useTranslation();
  return (
    <Router>
      {routes &&
      <Switch>
        {routes.map((route, routeIndex: number) => (
          <Route
            key={routeIndex}
            path={route.path}
            exact={route.exact}
            render={(routeProps: RouteComponentProps) => route.onBeforeRender({
              isLogged,
              ...routeProps,
              children: route.layout({
                ...routeProps,
                children: (
                  <Switch>
                    {route.pages.map((page: RouteComponent, index: number) => (
                      <Route
                        key={index}
                        path={page.path}
                        exact={page.exact}
                        render={(subRouteProps: RouteComponentProps) => (
                          <>
                            <Helmet>
                              {page.title &&
                              <title>{t(page.title)}</title>
                              }
                            </Helmet>
                            {route.onBeforeRender({
                              isLogged,
                              ...routeProps,
                              children: page.component({
                                ...subRouteProps,
                                isLogged
                              })
                            })}
                          </>
                        )}/>
                    ))}
                  </Switch>
                )
              })
            })}>
          </Route>
        ))}
        <Route path="*" render={({ location }) => (
          <Redirect
            to={{
              pathname: isLogged ? "/" : "/auth/login",
              state: { from: location }
            }}
          />
        )}/>
      </Switch>
      }
      <AlertManager/>
    </Router>
  );
};

export interface AppProps {
  isLogged: boolean
}

const mapStateToProps = (state: GlobalState) => {
  return {
    isLogged: state.session.isLogged
  };
};

export const App = connect(mapStateToProps)(AppComponent);
import React from "react";
import { TCentralPanelLayout } from "stories/layouts/TCentralPanelLayout";

const companyName = process.env.REACT_APP_COMPANY_NAME;

export const CentralPanelLayout = ({ children }: CentralPanelLayoutProps) => {
  return (
    <TCentralPanelLayout companyName={companyName}>
      {children}
    </TCentralPanelLayout>
  );
};

export interface CentralPanelLayoutProps {
  children?: React.ReactElement | React.ReactElement[]
}
import { createAction } from "@reduxjs/toolkit";
import {
  AUTH_STATE_CHANGE_ERROR,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_SUCCESS,
  LOGOUT_ERROR,
  LOGOUT_SUCCESS,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  SIGN_IN_ERROR,
  SIGN_IN_SUCCESS,
  SIGN_UP_ERROR,
  SIGN_UP_SUCCESS
} from "./session";
import { PERSONAL_DATA_SAVE_ERROR, PERSONAL_DATA_SAVED_SUCCESS, PROFILE_FETCH_ERROR } from "./account";
import { COMPANY_LIST_LOAD_ERROR } from "reducers/company";
import {
  CUSTOMER_CREATED_SUCCESS,
  CUSTOMER_CREATION_ERROR,
  CUSTOMER_LIST_LOAD_ERROR,
  CUSTOMER_LOG_CREATE_ERROR, CUSTOMER_LOG_CREATED_SUCCESS,
  CUSTOMER_NOT_FOUND, CUSTOMER_UPDATE_ERROR, CUSTOMER_UPDATED_SUCCESS
} from "reducers/customer";

export interface NotificationState {
  alert: object | null
}

const initialState: NotificationState = {
  alert: null
};

const addSuccessAlert = (state: NotificationState, message: String, autoHide: boolean) => {
  return {
    ...state,
    alert: {
      message: message,
      severity: "success",
      autoHide: autoHide
    }
  };
};

const addErrorAlert = (state: NotificationState, message: String, autoHide: boolean) => {
  return {
    ...state,
    alert: {
      message: message,
      severity: "error",
      autoHide: autoHide
    }
  };
};

export const DISMISS_ALERT = createAction("notification/dismiss_alert");

// Use the initialState as a default value
export function notificationReducer(state: NotificationState = initialState, action: any) {
  // The reducer normally looks at the action type field to decide what happens
  switch (action.type) {
    /**
     * Success messages
     */
    case SIGN_IN_SUCCESS.type:
    case FORGOT_PASSWORD_SUCCESS.type:
    case RESET_PASSWORD_SUCCESS.type:
    case SIGN_UP_SUCCESS.type:
    case PERSONAL_DATA_SAVED_SUCCESS.type:
    case CUSTOMER_CREATED_SUCCESS.type:
    case CUSTOMER_UPDATED_SUCCESS.type:
    case CUSTOMER_LOG_CREATED_SUCCESS.type:
    case LOGOUT_SUCCESS.type:
      return addSuccessAlert(state, action.payload.message, typeof action.payload.autoHide === "boolean" ? action.payload.autoHide : true);
    /**
     * Errors
     */
    case SIGN_IN_ERROR.type:
    case FORGOT_PASSWORD_ERROR.type:
    case RESET_PASSWORD_ERROR.type:
    case SIGN_UP_ERROR.type:
    case PERSONAL_DATA_SAVE_ERROR.type:
    case PROFILE_FETCH_ERROR.type:
    case LOGOUT_ERROR.type:
    case AUTH_STATE_CHANGE_ERROR.type:
    case COMPANY_LIST_LOAD_ERROR.type:
    case CUSTOMER_LIST_LOAD_ERROR.type:
    case CUSTOMER_CREATION_ERROR.type:
    case CUSTOMER_UPDATE_ERROR.type:
    case CUSTOMER_NOT_FOUND.type:
    case CUSTOMER_LOG_CREATE_ERROR.type:
      return addErrorAlert(state, action.payload.message, typeof action.payload.autoHide === "boolean" ? action.payload.autoHide : true);
    case DISMISS_ALERT.type:
      return {
        ...state,
        alert: null
      };
    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state;
  }
}
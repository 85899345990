import React from "react";
import { TSignUpForm } from "../../stories/components/forms/TSignUpForm";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { UserRemoteService } from "../../services/UserService";
import { AxiosHttpClient, HttpClientOptionsDefaults } from "../../services/HttpClient";
import { useDispatch } from "react-redux";
import { SIGN_UP_ERROR, SIGN_UP_SUCCESS } from "../../reducers/session";
import { useTranslation } from "react-i18next";

const SignUpFormComponent = ({ history, userService }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const backToLogin = () => {
    history.push("/auth/login");
  };

  const onSignUp = (form) => {
    return (resolve, reject) => {
      userService.signUp(form.email, form.firstName, form.lastName, form.password)
        .then(() => {
          dispatch(SIGN_UP_SUCCESS({
            message: t("Account created successfully")
          }));
          backToLogin();
          resolve();
        })
        .catch((error) => {
          dispatch(SIGN_UP_ERROR(error));
          reject(error);
        });
    }
  };

  return (
    <TSignUpForm
      onSignUp={onSignUp}
      onAlreadyHaveAccount={backToLogin} />
  )
}

export const SignUpForm = withRouter(SignUpFormComponent);

SignUpForm.propTypes = {
  userService: PropTypes.object.isRequired
}

SignUpForm.defaultProps = {
  userService: new UserRemoteService(new AxiosHttpClient(HttpClientOptionsDefaults))
}
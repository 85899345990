import React from "react";
import { Card, CardHeader, CircularProgress, Container, Divider, Grid, Theme } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/styles";
import { UserCompany } from "models/UserCompany";
import * as H from "history";
import { GlobalState } from "store";
import { connect, useDispatch } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import clsx from "clsx";
import { CompanyRemoteService, CompanyService, CompanySort } from "services/CompanyService";
import { AuthHttpClient, HttpClientOptionsDefaults } from "services/HttpClient";
import { COMPANY_LIST_LOAD_ERROR, COMPANY_LIST_LOADED_SUCCESS } from "reducers/company";
import { COMPANY_SELECTED } from "reducers/session";
import { FirebaseService } from "services/AuthService";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    flexGrow: 1,
    paddingTop: theme.spacing(2)
  },
  toolbar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main
  },
  cardContent: {
    display: "flex",
    minHeight: "150px",
    minWidth: "150px",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center"
  },
  clickable: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.grey[50]
    }
  }
}));

const defaultCompanyService = new CompanyRemoteService(new AuthHttpClient(HttpClientOptionsDefaults, FirebaseService.getInstance()));

const ChooseCompanyComponent = ({ companies = [], companyService = defaultCompanyService, history }: ChooseCompanyProps) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  /*const onCreateHandler = () => {
    history.push("/company/create");
  };*/

  React.useEffect(() => {
    if (companies.length === 0) {
      setLoading(true);
      companyService.getUserCompanies(CompanySort.DEFAULT_DESC)
        .then((companies: UserCompany[]) => {
          dispatch(COMPANY_LIST_LOADED_SUCCESS({
            companies: companies
          }));
        })
        .catch((error: any) => {
          dispatch(COMPANY_LIST_LOAD_ERROR(error));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onCompanyClickHandler = (company: UserCompany) => {
    dispatch(COMPANY_SELECTED({
      company: company
    }));
    history.push(`/companies/${company.id}/`);
  };

  return (
    <Container className={classes.container}>
      <Card>
        <CardHeader color="primary" title={t("Your companies")}/>
        <Divider/>
        <CardContent>
          <Grid container spacing={2}>
            {/*<Grid item xs={12} sm={3}>
              <Card>
                <CardContent className={clsx(classes.cardContent, classes.clickable)} onClick={onCreateHandler}>
                  <IconButton color="primary">
                    <Add/>
                  </IconButton>
                </CardContent>
              </Card>
            </Grid>*/}
            {companies.length > 0 && companies.map((company, index) => (
              <Grid item xs={12} sm={3} key={index}>
                <Card>
                  <CardContent onClick={() => onCompanyClickHandler(company)}
                               className={clsx(classes.cardContent, classes.clickable)}>
                    {company.displayName}
                  </CardContent>
                </Card>
              </Grid>
            ))}
            {loading &&
            <Grid item xs={12} sm={3}>
              <Card elevation={0}>
                <CardContent className={classes.cardContent}>
                  <CircularProgress/>
                </CardContent>
              </Card>
            </Grid>
            }
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export interface ChooseCompanyProps extends RouteComponentProps {
  companies: UserCompany[]
  history: H.History
  companyService?: CompanyService
}

const mapStateToProps = (state: GlobalState) => {
  return {
    companies: state.company.list
  };
};

export const ChooseCompany = connect(mapStateToProps)(withRouter(ChooseCompanyComponent));
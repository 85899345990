import { countryToFlag, fromCode } from "utils/countries";
import moment from "moment/moment";
import { Container, Theme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import { CheckCircle, Edit, Error } from "@material-ui/icons";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import React from "react";
import { RouteComponentProps } from "react-router";
import { TFunction } from "i18next";
import { Customer } from "models/Customer";
import { makeStyles } from "@material-ui/styles";
import Tooltip from "@material-ui/core/Tooltip";

export interface CustomerPersonalDetailsProps extends Partial<RouteComponentProps> {
  t: TFunction
  customer: Customer
  onEditClick: () => void
}

const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    padding: theme.spacing(2, 0, 2)
  }
}));

export const CustomerDetailsTab = ({ customer, t, onEditClick }: CustomerPersonalDetailsProps) => {
  const classes = useStyles();

  const country = fromCode(customer.country);
  const dateOfBirth = moment(customer.dateOfBirth);
  const drivingLicenseExpiryDate = moment(customer.drivingLicenseExpiryDate);

  return (
    <Container>
      <Grid container alignItems="center" spacing={2} className={classes.actions}>
        <Grid item xs={12} md={9}/>
        <Grid item xs={12} md={3}>
          <Hidden smDown>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={onEditClick}
              startIcon={<Edit/>}>
              {t("Edit")}
            </Button>
          </Hidden>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component="th" colSpan={2}>
                    {t("Personal details")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th">{t("First Name")}</TableCell>
                  <TableCell>{customer.firstName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">{t("Last Name")}</TableCell>
                  <TableCell>{customer.lastName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">{t("Date of birth")}</TableCell>
                  <TableCell>
                    {dateOfBirth.isValid() ? t("dateOfBirthYears", {
                      date: dateOfBirth.format("DD/MM/YYYY"),
                      years: moment().diff(dateOfBirth, "years")
                    }) : "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">{t("Driving license")}</TableCell>
                  <TableCell>
                    <DrivingLicenseCell t={t} drivingLicense={customer.drivingLicense} expiryDate={drivingLicenseExpiryDate}/>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">{t("Country")}</TableCell>
                  <TableCell>{country ? `${countryToFlag(country.code)} ${country.label}` : "-"}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={6}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component="th" colSpan={2}>
                    {t("Contact details")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th">{t("Email")}</TableCell>
                  <TableCell>{customer.email || "-"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">{t("Phone number")}</TableCell>
                  <TableCell>{customer.phoneNumber || "-"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">{t("Home address")}</TableCell>
                  <TableCell>{customer.homeAddress && customer.homePostalCode ? `${customer.homeAddress}, ${customer.homePostalCode}` : "-"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">{t("Work address")}</TableCell>
                  <TableCell>{customer.workAddress && customer.workPostalCode ? `${customer.workAddress}, ${customer.workPostalCode}` : "-"}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={6}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component="th" colSpan={2}>
                    {t("Family")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th">{t("Marital Status")}</TableCell>
                  <TableCell>{customer.maritalStatus ? t("marital_status_" + customer.maritalStatus) : "-"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">{t("Children number")}</TableCell>
                  <TableCell>{customer.childsNumber || "-"}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component="th" colSpan={2}>
                    {t("Additional information")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th">{t("Notes")}</TableCell>
                  <TableCell>{customer.notes || "-"}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
};

const useDrivingLicenseStyles = makeStyles((theme: Theme) => ({
  valid: {
    marginLeft: theme.spacing(1),
    color: theme.palette.success.light
  },
  invalid: {
    marginLeft: theme.spacing(1),
    color: theme.palette.error.light
  }
}));

interface DrivingLicenseCellProps {
  t: TFunction
  drivingLicense?: string
  expiryDate?: moment.Moment
}

const DrivingLicenseCell = ({ t, drivingLicense, expiryDate }: DrivingLicenseCellProps) => {
  const classes = useDrivingLicenseStyles();

  if (!drivingLicense) {
    return (
      <>-</>
    );
  }

  if (expiryDate && expiryDate.isValid() && expiryDate.isBefore(moment.now())) {
    return (
      <Grid container alignItems="center">
        {drivingLicense || "-"}
        <Tooltip title={t("Expired")}>
          <Error className={classes.invalid}/>
        </Tooltip>
      </Grid>
    );
  }

  return (
    <Grid container alignItems="center">
      {drivingLicense || "-"}
      <Tooltip title={t("Valid")}>
        <CheckCircle className={classes.valid}/>
      </Tooltip>
    </Grid>
  );
};
import React from "react";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import Autocomplete, { AutocompleteProps } from "@material-ui/lab/Autocomplete";
import { countries, Country, countryToFlag } from "utils/countries";
import Typography from "@material-ui/core/Typography";

export const CountryAutoComplete = ({ textProps, optionLabel, renderOption, ...props }: CountryAutoCompleteProps) => {
  const defaultRenderOption = (option: Country) => (
    <Typography variant="body1">{countryToFlag(option.code)} {option.label} ({option.code}) {option.phone}</Typography>
  );
  const defaultOptionLabel = (option: Country) => `${countryToFlag(option.code)} ${option.label}` || "";

  return (
    <Autocomplete
      options={countries}
      getOptionLabel={optionLabel || defaultOptionLabel}
      getOptionSelected={(option?: Country, value?: Country) => option?.code === value?.code}
      renderOption={renderOption || defaultRenderOption}
      renderInput={(params) => {
        const finalProps = {
          ...params,
          ...textProps,
          inputProps: {
            ...params.inputProps || {},
            ...textProps.inputProps || {},
            autoComplete: "new-password"
          }
        }
        return (
          <TextField
            variant="outlined"
            {...finalProps}/>
        )
      }}
      {...props}
    />
  );
}

export interface CountryAutoCompleteProps extends Partial<AutocompleteProps<Country, false, any, false>> {
  optionLabel?: (option: Country) => string
  textProps?: Partial<TextFieldProps>
}
import React from "react";
import { CustomersTable } from "components/tables/CustomersTable";
import { GlobalState } from "store";
import { connect } from "react-redux";
import { Container, Theme, Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Customer } from "models/Customer";
import { CustomerSort } from "services/CompanyService";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { Add } from "@material-ui/icons";
import Hidden from "@material-ui/core/Hidden";
import { RouteComponentProps, withRouter } from "react-router";
import { useTranslation } from "react-i18next";
import Fab from "@material-ui/core/Fab";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingBottom: theme.spacing(3)
  },
  toolbar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main
  },
  title: {
    flexGrow: 1
  },
  form: {
    padding: theme.spacing(2, 0, 2)
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
}));

const CustomersPageComponent = ({ companyId, customers, initialSort, total, history }: CustomersPageProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const onCreateClickHandler = () => {
    history.push(`/companies/${companyId}/customers/create`);
  };

  return (
    <Grid className={classes.container}>
      <Toolbar className={classes.toolbar}>
        <Typography component="h1" variant="h5" className={classes.title}>
          {t("Customers")}
        </Typography>
      </Toolbar>
      <Container>
        <Grid container alignItems="center" spacing={2} className={classes.form}>
          <Grid item xs={12} md={9}/>
          <Grid item xs={12} md={3}>
            <Hidden smDown>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={onCreateClickHandler}
                startIcon={<Add/>}>
                {t("New Customer")}
              </Button>
            </Hidden>
          </Grid>
        </Grid>
        <CustomersTable
          companyId={companyId}
          customers={customers}
          initialSort={initialSort}
          total={total}/>
      </Container>
      <Hidden smUp>
        <Tooltip title={t("New Customer")}>
          <Fab color="secondary" onClick={onCreateClickHandler} className={classes.fab}>
            <Add/>
          </Fab>
        </Tooltip>
      </Hidden>
    </Grid>
  );
};

export interface MatchParams {
  companyId: string
}

export interface CustomersPageProps extends RouteComponentProps {
  companyId: string
  customers: Customer[]
  initialSort: CustomerSort
  total: number
}

const mapStateToProps = (state: GlobalState) => {
  return {
    customers: state.customer.list,
    initialSort: state.customer.sort,
    total: state.customer.total
  };
};

export const CustomersPage = connect(mapStateToProps)(withRouter(CustomersPageComponent));
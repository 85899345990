import * as Countries from "utils/countries";
import { Country, countryToFlag } from "utils/countries";
import React, { ChangeEvent } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TFunction } from "i18next";
import { CustomerFormFields } from "components/forms/CustomerForm/index";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { CountryAutoComplete } from "components/CountryAutoComplete";
import Card from "@material-ui/core/Card";
import { FormikErrors } from "formik";

const useStyles = makeStyles((theme) => ({
  spacer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));

export interface ContactDetailsCardProps {
  className?: string;
  t: TFunction
  values: Partial<CustomerFormFields>
  errors: FormikErrors<Partial<CustomerFormFields>>
  hasError: (field: string) => boolean
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void
  handleAutoCompleteChange: (fieldName: string, map: (value: Country) => string) =>
    (event: ChangeEvent<{}>, value: Country) => void
}

export const ContactDetailsCard = ({ t, values, errors, hasError, handleChange, handleAutoCompleteChange, className }: ContactDetailsCardProps) => {
  const classes = useStyles();

  return (
    <Card className={className}>
      <CardHeader title={t("Contact details")}/>
      <Divider/>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Grid item xs={12} md={5}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label={t("Email")}
                name="email"
                autoComplete="new-password"
                error={hasError("email")}
                helperText={
                  hasError("email") ? errors.email[0] : null
                }
                onChange={handleChange}
                value={values.email || ""}
              />
            </Grid>
          </Grid>
          <Grid item xs={5} md={2}>
            <CountryAutoComplete
              id="country"
              disableClearable={true}
              autoSelect
              className={classes.spacer}
              placeholder="Country code"

              optionLabel={(option: Country) => `${countryToFlag(option.code)} ${option.phone}`}
              renderOption={(option: Country) => `${countryToFlag(option.code)} ${option.phone}`}
              textProps={{
                label: t("Country code"),
                placeholder: "Country code",
                error: hasError("phoneCountryCode"),
                helperText: hasError("phoneCountryCode") ? errors.phoneCountryCode[0] : null
              }}
              value={Countries.fromPhone(values.phoneCountryCode)}
              onChange={handleAutoCompleteChange("phoneCountryCode", (country => country.phone))}/>
          </Grid>
          <Grid item xs={7} md={4}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="phoneNumber"
              label={t("Phone number")}
              name="phoneNumber"
              autoComplete="new-password"
              error={hasError("phoneNumber")}
              helperText={
                hasError("phoneNumber") ? errors.phoneNumber[0] : null
              }
              onChange={handleChange}
              value={values.phoneNumber || ""}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="homeAddress"
              label={t("Home address")}
              name="homeAddress"
              autoComplete="new-password"
              error={hasError("homeAddress")}
              helperText={
                hasError("homeAddress") ? errors.homeAddress[0] : null
              }
              onChange={handleChange}
              value={values.homeAddress || ""}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="homePostalCode"
              label={t("Home Postal Code")}
              name="homePostalCode"
              autoComplete="new-password"
              error={hasError("homePostalCode")}
              helperText={
                hasError("homePostalCode") ? errors.homePostalCode[0] : null
              }
              onChange={handleChange}
              value={values.homePostalCode || ""}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="workAddress"
              label={t("Work address")}
              name="workAddress"
              autoComplete="new-password"
              error={hasError("workAddress")}
              helperText={
                hasError("workAddress") ? errors.workAddress[0] : null
              }
              onChange={handleChange}
              value={values.workAddress || ""}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="workPostalCode"
              label={t("Work Postal Code")}
              name="workPostalCode"
              autoComplete="new-password"
              error={hasError("workPostalCode")}
              helperText={
                hasError("workPostalCode") ? errors.workPostalCode[0] : null
              }
              onChange={handleChange}
              value={values.workPostalCode || ""}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
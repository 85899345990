import React from "react";
import PropTypes from "prop-types";
import TableRow from "@material-ui/core/TableRow";

export const TTableRow = ({ rowKey, children }: TTableRowProps) => {
  return (
    <TableRow
      hover
      tabIndex={-1}
      key={rowKey}
    >
      {children}
    </TableRow>
  )
}

export interface TTableRowProps {
  rowKey: string
  children?: React.ReactElement[]
}

TTableRow.propTypes = {
  rowKey: PropTypes.string.isRequired,
};
export const DEFAULT_PAGE_SIZE = 15;

export interface Pagination<T> {
  current_page: number
  data: T[]
  last_page: number
  per_page: number
  total: number
}

export interface Company {
  id: string
  display_name: string
  contact_email: string
}

export interface Customer {
  id: string
  number: bigint
  created_at: string
  company_id: string
  email: string
  first_name: string
  last_name: string
  country: string
  full_name?: string
  date_of_birth?: string
  driving_license?: string
  driving_license_expiry_date?: string
  phone_country_code?: string
  phone_number?: string
  marital_status?: string
  home_address: string
  home_postal_code: string
  work_address: string
  work_postal_code: string
  childs_number: number
  notes: string
}

export interface CustomerLog {
  id: string
  customer_id: string
  date: string
  type: string
  creator_name: string
  notes?: string
}

export interface User {
  email: string
  display_name: string
  first_name: string
  last_name: string
}

export interface UserCompany extends Company {
  default: boolean
}

export interface UserWithPassword extends User {
  password: string
}
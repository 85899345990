import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import PropTypes from "prop-types";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Menu as MenuIcon } from "@material-ui/icons";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { TTopMenu } from "./TTopMenu";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Slide from "@material-ui/core/Slide";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
    justifyContent: "flex-start"
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0, 0, 5, 0),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  mobileDrawer: {
    width: 250
  },
  fullList: {
    width: "auto"
  }
}));

const SwipeableMobileDrawer = ({ menus, open, onClose, onOpen }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <SwipeableDrawer
        anchor="left"
        open={open}
        onClose={onClose}
        onOpen={onOpen}
      >
        <div
          className={classes.mobileDrawer}
          role="presentation"
          onClick={onClose}
          onKeyDown={onClose}
        >
          {menus.map((menu, menuIndex) => (
            <div key={"menu-" + menuIndex}>
              <List>
                {menu.map((item) => (
                  <ListItem button key={item.title} onClick={item.onClick}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.title}/>
                  </ListItem>
                ))}
              </List>
              {menus.length - 1 !== menuIndex && <Divider/>}
            </div>
          ))}
        </div>
      </SwipeableDrawer>
    </React.Fragment>
  );
};

SwipeableMobileDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  menus: PropTypes.array
};

SwipeableMobileDrawer.defaultProps = {
  menus: []
};

const HideOnScroll = ({ children, window }) => {
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });
  const isSmallDevice = useMediaQuery(theme => theme.breakpoints.down("sm"));

  return (
    <Slide appear={false} direction="down" in={!isSmallDevice || !trigger}>
      {children}
    </Slide>
  );
};

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
};

export const TDashboardLayout = ({ children, companyName, drawerMenus, topMenus }) => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const isSmallDevice = useMediaQuery(theme => theme.breakpoints.down("sm"));

  let desktopDrawerOpen = !isSmallDevice;
  let mobileDrawerOpen = drawerOpen && isSmallDevice;

  const onDrawerButtonClick = () => {
    setDrawerOpen(!drawerOpen);
  };

  const onDrawerOpenHandler = () => {
    setDrawerOpen(true);
  };

  const onDrawerCloseHandler = () => {
    setDrawerOpen(false);
  };

  return (
    <div className={classes.root}>
      <HideOnScroll>
        <AppBar
          position="fixed"
          elevation={0}
          className={clsx(classes.appBar, {
            [classes.appBarShift]: desktopDrawerOpen
          })}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label={(drawerOpen ? "open" : "close") + " drawer"}
              onClick={onDrawerButtonClick}
              className={clsx(classes.menuButton, desktopDrawerOpen && classes.hide)}
            >
              <MenuIcon/>
            </IconButton>
            <div className={classes.title}/>
            {topMenus.map((menu, index) => {
              return <TTopMenu
                key={"top-menu-" + index}
                title={menu.title}
                icon={menu.icon}
                onMenuClick={menu.onClick}
                menus={menu.menus || []}
              />;
            })}
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={desktopDrawerOpen}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div className={classes.drawerHeader}>
          {companyName}
        </div>
        <Divider/>
        {drawerMenus.map((menu, menuIndex) => (
          <div key={"menu-" + menuIndex}>
            <List>
              {menu.map((item) => (
                <ListItem button key={item.title} onClick={item.onClick}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.title}/>
                </ListItem>
              ))}
            </List>
            <Divider/>
          </div>
        ))}
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: desktopDrawerOpen
        })}>
        <div className={classes.toolbar}/>
        {children}
      </main>
      <SwipeableMobileDrawer
        onOpen={onDrawerOpenHandler}
        onClose={onDrawerCloseHandler}
        open={mobileDrawerOpen}
        menus={drawerMenus}/>
    </div>
  );
};

TDashboardLayout.propTypes = {
  companyName: PropTypes.element.isRequired,
  drawerMenus: PropTypes.array,
  topMenus: PropTypes.array
};

TDashboardLayout.defaultProps = {
  companyName: <></>,
  drawerMenus: [],
  topMenus: []
};

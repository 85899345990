import React from "react";
import { TForgotPasswordForm } from "../../stories/components/forms/TForgotPasswordForm";
import { useDispatch } from "react-redux";
import { FirebaseService } from "../../services/AuthService";
import { FORGOT_PASSWORD_ERROR, FORGOT_PASSWORD_SUCCESS } from "../../reducers/session";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

const ForgotPasswordFormComponent = ({ authService, history }) => {
  let dispatch = useDispatch();

  const onResetPassword = (email) => {
    return (resolve, reject) => {
      authService.sendPasswordResetEmail(email)
        .then(() => {
          dispatch(FORGOT_PASSWORD_SUCCESS({
            message: "Reset password email sent"
          }));
          onBackToLogin();
          resolve();
        })
        .catch((error) => {
          dispatch(FORGOT_PASSWORD_ERROR(error));
          reject(error);
        })
    }
  };

  const onBackToLogin = () => {
    history.push("/auth/login");
  };

  return (
    <TForgotPasswordForm
       onResetPassword={onResetPassword}
       onBackToLogin={onBackToLogin}/>
  );
};

export const ForgotPasswordForm = withRouter(ForgotPasswordFormComponent);

ForgotPasswordForm.propTypes = {
  authService: PropTypes.object.isRequired
}

ForgotPasswordForm.defaultProps = {
  authService: FirebaseService.getInstance()
}
import React from "react";
import { TProfileForm } from "../stories/components/forms/TProfileForm";
import PropTypes from "prop-types";
import { UserRemoteService } from "../services/UserService";
import { AuthHttpClient, HttpClientOptionsDefaults } from "../services/HttpClient";
import { useDispatch } from "react-redux";
import {
  PERSONAL_DATA_SAVE_ERROR,
  PERSONAL_DATA_SAVED_SUCCESS,
  PROFILE_FETCH_ERROR,
  PROFILE_FETCHED_SUCCESS
} from "../reducers/account";
import Grid from "@material-ui/core/Grid";
import { Container, Divider, Toolbar } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CardHeader from "@material-ui/core/CardHeader";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Skeleton } from "@material-ui/lab";
import { FirebaseService } from "../services/AuthService";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main
  },
  container: {
    paddingTop: theme.spacing(2)
  },
  hide: {
    display: "none"
  },
}));

const AccountSettingsComponent = ({ userService }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);

  const onLoad = () => {
    setLoading(true);
    return (resolve, reject) => {
      userService.getProfile()
        .then((user) => {
          dispatch(PROFILE_FETCHED_SUCCESS({
            user: user
          }));
          resolve(user);
          setLoading(false);
        })
        .catch((error) => {
          dispatch(PROFILE_FETCH_ERROR(error));
          reject(error);
        });
    };
  };

  const onSave = (form) => {
    return (resolve, reject) => {
      userService.saveProfile(form.displayName, form.firstName, form.lastName)
        .then((user) => {
          dispatch(PERSONAL_DATA_SAVED_SUCCESS({
            user: {
              displayName: user.displayName,
              firstName: user.firstName,
              lastName: user.lastName
            },
            message: t("Personal data has been saved")
          }));
          resolve();
        })
        .catch((error) => {
          dispatch(PERSONAL_DATA_SAVE_ERROR(error));
          reject(error);
        });
    };
  };

  return (
    <Grid>
      <Toolbar className={classes.toolbar}>
        <Typography component="h1" variant="h5">
          {t("Account Settings")}
        </Typography>
      </Toolbar>
      <LinearProgress color="secondary" className={clsx({
        [classes.hide]: !loading
      })}/>
      <Container className={classes.container}>
        <Skeleton className={clsx({
          [classes.hide]: !loading
        })} variant="rect" height={350}/>
        <Card className={clsx({
          [classes.hide]: loading
        })}>
          <CardHeader title={t("Personal details")} />
          <Divider />
          <CardContent>
            <TProfileForm
              onSave={onSave}
              onLoad={onLoad}/>
          </CardContent>
        </Card>
      </Container>
    </Grid>
  );
};

export const AccountSettings = AccountSettingsComponent;

AccountSettings.propTypes = {
  userService: PropTypes.object.isRequired
};

AccountSettings.defaultProps = {
  userService: new UserRemoteService(new AuthHttpClient(HttpClientOptionsDefaults, FirebaseService.getInstance()))
};
import validate from "validate.js";
import moment from "moment";

export const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";
export const DEFAULT_DATETIME_FORMAT = "YYYY-MM-DD hh:mm:ss";

interface ValidateDateOptions {
  dateOnly?: boolean;
  latest?: moment.Moment;
  earliest?: moment.Moment;
  message?: string;
}

// Before using it we must add the parse and format functions
// Here is a sample implementation using moment.js
validate.extend(validate.validators.datetime, {
  // The value is guaranteed not to be null or undefined but otherwise it
  // could be anything.
  parse: (value: number, options: ValidateDateOptions) => {
    return +moment.utc(value);
  },
  // Input is a unix timestamp
  format: (value: any, options: ValidateDateOptions) => {
    let format = options.dateOnly ? DEFAULT_DATE_FORMAT : DEFAULT_DATETIME_FORMAT;
    return moment.unix(value).format(format);
  }
});
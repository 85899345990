import React from "react";
import { Theme, Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { ArrowBack, Edit } from "@material-ui/icons";
import Hidden from "@material-ui/core/Hidden";
import { Redirect, RouteComponentProps, withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Customer } from "models/Customer";
import { GlobalState } from "store";
import { CUSTOMER_NOT_FOUND } from "reducers/customer";
import { useTranslation } from "react-i18next";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Fab from "@material-ui/core/Fab";
import { CustomerDetailsTab } from "pages/CustomerProfilePage/components/CustomerDetailsTab";
import { CustomerLogsTab } from "pages/CustomerProfilePage/components/CustomerLogsTab";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(4)
  },
  toolbar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main
  },
  title: {
    flexGrow: 1
  },
  fab: {
    position: "fixed",
    top: "auto",
    left: "auto",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
}));

interface TabPanelProps {
  children?: React.ReactNode;
  tabIndex: any;
  value: any;
}

const TabPanel = ({ children, value, tabIndex, ...other }: TabPanelProps) => {
  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      hidden={value !== tabIndex}
      aria-labelledby={`scrollable-auto-tab-${tabIndex}`}
      className={classes.container}
      {...other}
    >
      {value === tabIndex && children}
    </div>
  );
};

const CustomerProfileComponent = ({ companyId, customerId, history, location }: CustomersPageProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const customer: Customer = useSelector<GlobalState, Customer>(state =>
    state.customer.list.find(customer => customer.id === customerId));
  const [currentTab, setTab] = React.useState<any>(0);

  const handleChange = (event: React.ChangeEvent<{}>, tabNumber: number) => {
    setTab(tabNumber);
  };

  if (!customer) {
    dispatch(CUSTOMER_NOT_FOUND({ message: t("Customer not found") }));
    return (
      <Redirect
        to={{
          pathname: `/companies/${companyId}/customers`,
          state: { from: location }
        }}
      />
    );
  }

  const onEditClickHandler = () => {
    history.push(`/companies/${companyId}/customers/${customer.id}/edit`);
  };

  const onBackClickHandler = () => {
    history.goBack();
  };

  return (
    <Grid>
      <Toolbar className={classes.toolbar}>
        <IconButton color="inherit" onClick={onBackClickHandler}>
          <ArrowBack/>
        </IconButton>
        <Typography component="h1" variant="h5" className={classes.title}>
          {t("Customer")}
        </Typography>
      </Toolbar>
      <AppBar position="static">
        <Tabs value={currentTab} onChange={handleChange} centered>
          <Tab label={t("Personal details")}/>
          <Tab label={t("Log")}/>
        </Tabs>
      </AppBar>
      <TabPanel value={currentTab} tabIndex={0}>
        <CustomerDetailsTab
          history={history}
          customer={customer}
          t={t}
          onEditClick={onEditClickHandler}
        />
      </TabPanel>
      <TabPanel value={currentTab} tabIndex={1}>
        <CustomerLogsTab customer={customer}/>
      </TabPanel>
      <Hidden smUp>
        <Tooltip title={t("Edit")} className={classes.fab}>
          <Fab color="secondary" onClick={onEditClickHandler}>
            <Edit/>
          </Fab>
        </Tooltip>
      </Hidden>
    </Grid>
  );
};

export interface CustomersPageProps extends RouteComponentProps {
  companyId: string
  customerId: string
}

export const CustomerProfilePage = withRouter(CustomerProfileComponent);
import { CustomerLog, CustomerLogTypeIcon } from "models/Customer";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React, { ChangeEvent, useEffect, useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { FormState, ValidationForm } from "components/ValidationForm";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { ProgressButton } from "stories/components/ProgressButton";

export interface CustomerLogDialogProps {
  open: boolean
  onSave: (form: CustomerLogFormFields) => Promise<any>
  onClose: () => void
  log?: CustomerLog
}

export interface CustomerLogFormFields extends CustomerLog, Object {
}

const useDialogStyles = makeStyles((theme: Theme) => ({
  title: {
    flexGrow: 1
  }
}));

export const CustomerLogDialog = ({ open, onClose, log, onSave }: CustomerLogDialogProps) => {
  const classes = useDialogStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const isSmallDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const initialState: FormState<CustomerLogFormFields> = {
    isValid: false,
    values: {} as CustomerLog,
    errors: {},
    touched: {}
  };

  const [formState, setFormState] = useState(initialState);

  useEffect(() => {
    setFormState((formState) => ({
      ...formState,
      values: {
        id: log?.id,
        type: log?.type,
        customerId: log?.customerId,
        notes: log?.notes
      }
    }));
  }, [log]);

  const validators = {
    notes: {
      presence: { allowEmpty: false, message: t("isRequired", { context: "male", field: t("Notes") }) }
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value
      }
    }));
  };

  const hasError = (field: string) => {
    return formState.touched[field] && field in formState.errors;
  };

  const resetFormState = () => {
    setFormState(initialState);
  };

  const onSaveHandler = () => {
    if (!onSave) {
      console.warn("onSave is not defined");
    }

    setLoading(true);
    onSave(formState.values)
      .finally(() => {
        setLoading(false);
        resetFormState();
      });
  };

  if (!log) {
    return null;
  }

  return (
    <ValidationForm
      validators={validators}
      formState={formState}
      setFormState={setFormState}
      onSubmit={onSaveHandler}>
      <Dialog fullScreen={isSmallDevice} open={open} onClose={onClose} fullWidth>
        <DialogTitle disableTypography>
          {formState.values.type &&
          <Grid container>
            <Typography component="h2" variant="subtitle1" className={classes.title}>
              {formState.values.id ? t("Edit {entity}", { entity: t("log_type_" + formState.values.type) }) :
                t("Add {entity}", { entity: t("log_type_" + formState.values.type) })}
            </Typography>
            <Icon component={CustomerLogTypeIcon[formState.values.type]}/>
          </Grid>
          }
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                variant="outlined"
                id="notes"
                name="notes"
                label={t("Notes")}
                value={formState.values.notes || ""}
                autoComplete="new-password"
                error={hasError("notes")}
                helperText={
                  hasError("notes") ? formState.errors.notes[0] : null
                }
                onChange={handleChange}
                rows={5}
                multiline
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {t("Cancel")}
          </Button>
          <ProgressButton
            type="submit"
            color="primary"
            variant="contained"
            disabled={!formState.isValid}
            loading={loading}
            onClick={onSaveHandler}>
            {t("Save")}
          </ProgressButton>
        </DialogActions>
      </Dialog>
    </ValidationForm>
  );
};
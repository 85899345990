import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment/moment";
import { CountryAutoComplete } from "components/CountryAutoComplete";
import * as Countries from "utils/countries";
import { Country, countryToFlag } from "utils/countries";
import Card from "@material-ui/core/Card";
import React, { ChangeEvent } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TFunction } from "i18next";
import { CustomerFormFields } from "components/forms/CustomerForm/index";
import { FormikErrors } from "formik";

const useStyles = makeStyles((theme) => ({
  spacer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));

export interface PersonalDetailsFormProps {
  t: TFunction
  values: Partial<CustomerFormFields>
  errors: FormikErrors<Partial<CustomerFormFields>>
  hasError: (field: string) => boolean
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void
  handleDateChange: (fieldName: string) => (date: moment.Moment, value?: string) => void
  handleAutoCompleteChange: (fieldName: string, map: (value: Country) => string) =>
    (event: ChangeEvent<{}>, value: Country) => void
}

export const PersonalDetailsCard = ({ t, values, errors, hasError, handleChange, handleDateChange, handleAutoCompleteChange }: PersonalDetailsFormProps) => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader title={t("Personal details")}/>
      <Divider/>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              autoFocus
              id="firstName"
              label={t("First Name")}
              name="firstName"
              required
              autoComplete="new-password"
              error={hasError("firstName")}
              helperText={
                hasError("firstName") ? errors.firstName[0] : null
              }
              onChange={handleChange}
              value={values.firstName || ""}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="lastName"
              label={t("Last Name")}
              name="lastName"
              required
              autoComplete="new-password"
              error={hasError("lastName")}
              helperText={
                hasError("lastName") ? errors.lastName[0] : null
              }
              onChange={handleChange}
              value={values.lastName || ""}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <KeyboardDatePicker
              autoOk
              disableFuture
              fullWidth
              id="dateOfBirth"
              name="dateOfBirth"
              className={classes.spacer}
              inputVariant="outlined"
              openTo="year"
              format="DD/MM/YYYY"
              label={t("Date of birth")}
              views={["year", "month", "date"]}
              error={hasError("dateOfBirth")}
              helperText={
                hasError("dateOfBirth") ? errors.dateOfBirth[0] : null
              }
              value={values.dateOfBirth || null}
              maxDate={moment.utc().subtract(18, "years")}
              initialFocusedDate={moment.utc().subtract(18, "years")}
              onChange={handleDateChange("dateOfBirth")}/>
          </Grid>
          <Grid item xs={12} md={4}>
            <CountryAutoComplete
              id="country"
              autoSelect
              className={classes.spacer}
              optionLabel={(option: Country) => `${countryToFlag(option.code)} ${option.label}`}
              renderOption={(option: Country) => `${countryToFlag(option.code)} ${option.label}`}
              placeholder="Country"
              textProps={{
                label: t("Country"),
                placeholder: "Country",
                error: hasError("country"),
                helperText: hasError("country") ? errors.country[0] : null
              }}
              value={Countries.fromCode(values.country)}
              onChange={handleAutoCompleteChange("country", (country => country?.code))}/>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="drivingLicense"
              label={t("Driving license")}
              name="drivingLicense"
              autoComplete="new-password"
              error={hasError("drivingLicense")}
              helperText={
                hasError("drivingLicense") ? errors.drivingLicense[0] : null
              }
              onChange={handleChange}
              value={values.drivingLicense || ""}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <KeyboardDatePicker
              autoOk
              disablePast
              fullWidth
              id="drivingLicenseExpiryDate"
              name="drivingLicenseExpiryDate"
              className={classes.spacer}
              inputVariant="outlined"
              openTo="year"
              format="DD/MM/YYYY"
              label={t("Expiry Date")}
              views={["year", "month", "date"]}
              error={hasError("drivingLicenseExpiryDate")}
              helperText={
                hasError("drivingLicenseExpiryDate") ? errors.drivingLicenseExpiryDate[0] : null
              }
              value={values.drivingLicenseExpiryDate || null}
              onChange={handleDateChange("drivingLicenseExpiryDate")}/>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  wrapper: {
    position: "relative"
  },
  buttonProgress: {
    color: theme.secondaryColor,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

export const ProgressButton = ({ loading, children, ...props }) => {
  const classes = useStyles();
  const disabled = (props && props.disabled) || false;

  return (
    <div className={classes.wrapper}>
      <Button {...props} disabled={disabled || loading}>
        {children}
      </Button>
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  );
};

ProgressButton.propTypes = {
  loading: PropTypes.bool.isRequired
};

ProgressButton.defaultProps = {
  loading: false
};

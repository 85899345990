import firebase from "firebase/app";
import { firebaseAuth } from "./Firebase";

export enum AuthErrorCode {
  INVALID_OR_EXPIRED_CODE = "auth/invalid_or_expired_code",
  INVALID_EMAIL_OR_PASSWORD = "auth/invalid_email_or_password",
  INVALID_EMAIL = "auth/invalid_email",
  UNEXPECTED_ERROR = "auth/unexpected_error",
  TOKEN_REVOKED = "auth/token_revoked",
}

export class OAuthUser {
  private readonly _id: string;
  private readonly _displayName: string;
  private readonly _accessToken: string;
  private readonly _refreshToken: string;

  constructor(id: string, displayName: string, accessToken: string, refreshToken: string) {
    this._id = id;
    this._displayName = displayName;
    this._accessToken = accessToken;
    this._refreshToken = refreshToken;
  }

  get id(): string {
    return this._id;
  }

  get displayName(): string {
    return this._displayName;
  }

  get accessToken(): string {
    return this._accessToken;
  }

  get refreshToken(): string {
    return this._refreshToken;
  }
}

export interface AuthService {
  signInWithEmailAndPassword(email: string, password: string): Promise<OAuthUser>

  signInWithGoogle(): Promise<OAuthUser>

  signInWithFacebook(): Promise<OAuthUser>

  onAuthStateChanged(unsubscribe: Function): Promise<OAuthUser | null>

  getToken(forceRefresh?: boolean): Promise<string>

  sendPasswordResetEmail(email: string): Promise<void>

  confirmPasswordReset(code: string, newPassword: string): Promise<void>

  logout(): Promise<void>
}

export class FirebaseService implements AuthService {
  static DEFAULT_AUTH_STORAGE = firebase.auth.Auth.Persistence.SESSION;

  static _instance: FirebaseService;
  _sdk?: firebase.auth.Auth = undefined;

  static getInstance() {
    if (!this._instance) {
      this._instance = new FirebaseService();
    }
    return this._instance;
  }

  private getSDK() {
    if (!this._sdk) {
      this._sdk = firebaseAuth;
    }
    return this._sdk;
  }

  signInWithEmailAndPassword(email: string, password: string): Promise<OAuthUser> {
    return new Promise<OAuthUser>((resolve, reject) => {
      this.getSDK().setPersistence(FirebaseService.DEFAULT_AUTH_STORAGE)
        .then(() => {
          return this.getSDK().signInWithEmailAndPassword(email, password)
            .then((data) => {
              data!.user.getIdToken()
                .then((idToken) => {
                  resolve(new OAuthUser(
                    data!.user.uid,
                    data!.user.displayName,
                    idToken,
                    data!.user.refreshToken
                  ));
                });
            });
        })
        .catch((error) => {
          reject(FirebaseService.transformErrorMessage(error.code));
        });
    });
  }

  signInWithGoogle(): Promise<OAuthUser> {
    return new Promise<OAuthUser>((resolve, reject) => {
      this.getSDK().setPersistence(FirebaseService.DEFAULT_AUTH_STORAGE)
        .then(() => {
          return this.getSDK().signInWithPopup(new firebase.auth.GoogleAuthProvider())
            .then((data) => {
              data!.user.getIdToken()
                .then((idToken) => {
                  resolve(new OAuthUser(
                    data!.user.uid,
                    data!.user.displayName,
                    idToken,
                    data!.user.refreshToken
                  ));
                });
            });
        })
        .catch((error) => {
          reject(FirebaseService.transformErrorMessage(error.code));
        });
    });
  }

  signInWithFacebook(): Promise<OAuthUser> {
    return new Promise<OAuthUser>((resolve, reject) => {
      this.getSDK().setPersistence(FirebaseService.DEFAULT_AUTH_STORAGE)
        .then(() => {
          return this.getSDK().signInWithPopup(new firebase.auth.FacebookAuthProvider())
            .then((data) => {
              data!.user.getIdToken()
                .then((idToken) => {
                  resolve(new OAuthUser(
                    data!.user.uid,
                    data!.user.displayName,
                    idToken,
                    data!.user.refreshToken
                  ));
                });
            });
        })
        .catch((error) => {
          reject(FirebaseService.transformErrorMessage(error.code));
        });
    });
  }

  /**
   * It will resolve in a firebase user if there is an activate session.
   * Note: A null object will be returned if the user is not authenticated or the token has been revoked.
   */
  onAuthStateChanged(unsubscribe: Function): Promise<OAuthUser> {
    return new Promise<OAuthUser>((resolve, reject) => {
      unsubscribe = this.getSDK().onAuthStateChanged((user) => {
        if (user) {
          return user.getIdToken()
            .then((idToken) => {
              resolve(new OAuthUser(
                user.uid,
                user.displayName,
                idToken,
                user.refreshToken
              ));
            });
        } else {
          resolve(null);
        }
      }, (error) => {
        reject(FirebaseService.transformErrorMessage(error.code));
      });
    });
  }

  getToken(forceRefresh: boolean = false): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.getSDK().currentUser?.getIdToken(forceRefresh)
        .then((token) => {
          resolve(token);
        })
        .catch((error) => {
          reject(FirebaseService.transformErrorMessage(error.code));
        });
    });
  }

  sendPasswordResetEmail(email: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.getSDK().sendPasswordResetEmail(email)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(FirebaseService.transformErrorMessage(error.code));
        });
    });
  }

  confirmPasswordReset(code: string, newPassword: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.getSDK().confirmPasswordReset(code, newPassword)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(FirebaseService.transformErrorMessage(error.code));
        });
    });
  }

  logout(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.getSDK().signOut()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(FirebaseService.transformErrorMessage(error.code));
        });
    });
  }

  private static transformErrorMessage(code: string) {
    switch (code) {
      case "auth/invalid-email":
      case "auth/user-disabled":
      case "auth/user-not-found":
        return {
          code: AuthErrorCode.INVALID_EMAIL,
          message: "Invalid email"
        };
      case "auth/wrong-password":
        return {
          code: AuthErrorCode.INVALID_EMAIL_OR_PASSWORD,
          message: "Invalid email or password"
        };
      case "auth/expired-action-code":
      case "auth/invalid-action-code":
        return {
          code: AuthErrorCode.INVALID_OR_EXPIRED_CODE,
          message: "Reset password link is invalid or has expired. Please ask for a new link."
        };
      case "auth/id-token-revoked":
        return {
          code: AuthErrorCode.TOKEN_REVOKED,
          message: "Access has been revoked."
        };
    }

    return {
      code: AuthErrorCode.UNEXPECTED_ERROR,
      message: "An unexpected error occurred. Please try again."
    };
  }
}
import { createAction } from "@reduxjs/toolkit";
import { User } from "models/User";

export interface SessionState {
  user: User
}

const initialState: SessionState = {
  user: null
}

interface ProfileFetchedSuccessAction {
  user: User
}

interface ProfileSavedSuccessAction extends AlertAction {
  user: User
}

interface AlertAction {
  message: String
}

export const PERSONAL_DATA_SAVED_SUCCESS = createAction<ProfileSavedSuccessAction>('account/profile_saved_success');
export const PERSONAL_DATA_SAVE_ERROR = createAction<AlertAction>('account/profile_save_error');
export const PROFILE_FETCHED_SUCCESS = createAction<ProfileFetchedSuccessAction>('account/profile_fetched_success');
export const PROFILE_FETCH_ERROR = createAction<AlertAction>('account/profile_fetch_error');

// Use the initialState as a default value
export function accountReducer(state: SessionState = initialState, action: any) {
  // The reducer normally looks at the action type field to decide what happens
  switch (action.type) {
    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state
  }
}
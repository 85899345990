import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { TTopMenu, TTopMenuProps } from "./TTopMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  appBar: {
    position: "fixed",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  title: {
    flexGrow: 1,
    justifyContent: "flex-start"
  },
  hide: {
    display: "none"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0, 0, 5, 0),
    position: "relative"
  },
}));

export const TLandingLayout = ({ children, topMenus, companyName }: TLandingLayoutProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar
        elevation={0}
        className={classes.appBar}
      >
        <Toolbar>
          <div>{companyName}</div>
          <div className={classes.title}/>
          {topMenus.map((menu, index) => {
            return <TTopMenu
              key={"top-menu-" + index}
              title={menu.title}
              icon={menu.icon}
              onMenuClick={menu.onMenuClick}
              menus={menu.menus || []}
            />;
          })}
        </Toolbar>
      </AppBar>
      <main
        className={classes.content}>
        <div className={classes.toolbar}/>
        {children}
      </main>
    </div>
  );
};

export interface TLandingLayoutProps {
  companyName: React.ReactElement
  topMenus: TTopMenuProps[]
  children?: React.ReactElement
}

import firebase from "firebase";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { AuthService } from "./AuthService";
import i18n from "utils/i18n";

export enum ErrorResponseCode {
  UNEXPECTED = "unexpected",
  CONFLICT = "conflict",
  VALIDATION = "validation",
}

export interface HttpClient {
  get<T>(path: string, options?: HttpClientOptions): Promise<T>

  post<T>(path: string, data: any, options?: HttpClientOptions): Promise<T>

  put<T>(path: string, data: any, options?: HttpClientOptions): Promise<T>
}

export interface HttpClientOptions {
  baseURL?: string
  timeout?: number
  headers?: object
  params?: object
  performance?: firebase.performance.Performance
}

export interface ErrorResponse {
  code: string
  message: string
  errors?: ErrorResponseError
}

interface ErrorResponseError {
  [field: string]: string[]
}

interface ServerErrorResponseError {
  [field: string]: ServerErrorResponseErrorReason;
}

interface ServerErrorResponseErrorReason {
  [reason: string]: string;
}

interface ServerErrorResponse {
  code: string
  errors: ServerErrorResponseError
}

export const HttpClientOptionsDefaults: HttpClientOptions = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: Number(process.env.REACT_APP_API_TIMEOUT),
  headers: {
    "User-Agent": navigator.userAgent + " Insurance-Web/" + process.env.REACT_APP_API_USER_AGENT,
    "Accept": "application/json",
    "Content-Type": "application/json;charset=UTF-8",
    "Accept-Language": i18n.language,
  }
};

export class AxiosHttpClient implements HttpClient {
  protected readonly client: AxiosInstance;

  constructor(options: HttpClientOptions) {
    this.client = axios.create({
      baseURL: options.baseURL,
      timeout: options.timeout,
      headers: options.headers
    } as AxiosRequestConfig);
  }

  public get<T>(path: string, options?: HttpClientOptions): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.client.get<T>(path, options as AxiosRequestConfig)
        .then((response: AxiosResponse) => {
          resolve(response.data as T);
        })
        .catch(this.promiseCatch(reject));
    });
  }

  public post<T>(path: string, data: any, options?: HttpClientOptions): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.client.post<T>(path, data, options as AxiosRequestConfig)
        .then((response: AxiosResponse) => {
          resolve(response.data as T);
        })
        .catch(this.promiseCatch(reject));
    });
  }

  public put<T>(path: string, data: any, options?: HttpClientOptions): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.client.put<T>(path, data, options as AxiosRequestConfig)
        .then((response: AxiosResponse) => {
          resolve(response.data as T);
        })
        .catch(this.promiseCatch(reject));
    });
  }

  private static convertToErrorResponse(response: AxiosResponse): ErrorResponse {
    let errorResponse = response.data as ServerErrorResponse

    switch (errorResponse.code) {
      case "conflict":
        return {
          code: ErrorResponseCode.CONFLICT,
          message: "This resource already exists.",
          errors: this.extractErrors(errorResponse.errors)
        }
      case "validation_exception":
        return {
          code: ErrorResponseCode.VALIDATION,
          message: "One or multiple fields are invalid.",
          errors: this.extractErrors(errorResponse.errors)
        }
    }

    return {
      code: ErrorResponseCode.UNEXPECTED,
      message: "An unexpected error occurred.",
      errors: this.extractErrors(errorResponse.errors)
    }
  }

  private promiseCatch(reject: any): any {
    return (error: any) => {
      if (error.response) {
        reject(AxiosHttpClient.convertToErrorResponse(error.response));
        return;
      }

      if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      }

      reject(error);
    }
  }

  private static extractErrors(errors: ServerErrorResponseError): ErrorResponseError {
    if (!errors || Object.keys(errors).length === 0) {
      return {}
    }

    let fieldErrors: ErrorResponseError = {}
    for (const field in errors) {
      for (const reason in errors[field]) {
        fieldErrors[field] = [
          ...fieldErrors[field] || [],
          errors[field][reason]
        ];
      }
    }

    return fieldErrors;
  }
}

export class AuthHttpClient extends AxiosHttpClient {
  private _authService: AuthService;

  constructor(options: HttpClientOptions, authService: AuthService) {
    super(options);
    this._authService = authService;
  }

  get<T>(path: string, options?: HttpClientOptions): Promise<T> {
    return this._authService.getToken()
      .then((authToken) => {
        return super.get(path, {
          headers: {
            "Authorization": "Bearer " + authToken,
          },
          ...options
        } as HttpClientOptions);
      });
  }

  post<T>(path: string, data: any, options?: HttpClientOptions): Promise<T> {
    return this._authService.getToken()
      .then((authToken) => {
        return super.post(path, data, {
          headers: {
            "Authorization": "Bearer " + authToken,
          },
          ...options
        } as HttpClientOptions);
      });
  }

  put<T>(path: string, data: any, options?: HttpClientOptions): Promise<T> {
    return this._authService.getToken()
      .then((authToken) => {
        return super.put(path, data, {
          headers: {
            "Authorization": "Bearer " + authToken,
          },
          ...options
        } as HttpClientOptions);
      });
  }
}
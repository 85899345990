import { FirebaseTrackingService, TrackingEventNames, TrackingService } from "services/TrackingService";
import { MENU_SELECTED, MenuSelectedAction } from "./navigation";
import { COMPANY_SELECTED, CompanySelected, LOGOUT_SUCCESS, SIGN_IN_SUCCESS } from "./session";

export interface TrackingState {
}

const initialState: TrackingState = {};

const trackingService: TrackingService = FirebaseTrackingService.getInstance();

// Use the initialState as a default value
export function trackingReducer(state: TrackingState = initialState, action: any) {
  // The reducer normally looks at the action type field to decide what happens
  switch (action.type) {
    case SIGN_IN_SUCCESS.type:
      trackingService.trackEvent(TrackingEventNames.SIGN_IN);
      break;
    case LOGOUT_SUCCESS.type:
      trackingService.trackEvent(TrackingEventNames.LOGOUT);
      break;
    case MENU_SELECTED.type:
      let menuSelectedPayload: MenuSelectedAction = action.payload;
      trackingService.trackEvent(TrackingEventNames.MENU_SELECTED, {
        item_position: menuSelectedPayload.position,
        item_name: menuSelectedPayload.menu
      });
      break;
    case COMPANY_SELECTED.type:
      let companySelectedPayload: CompanySelected = action.payload;
      trackingService.trackEvent(TrackingEventNames.COMPANY_SELECTED, {
        item_id: companySelectedPayload.company.id
      });
      break;
  }

  // If this reducer doesn't recognize the action type, or doesn't
  // care about this specific action, return the existing state unchanged
  return state;
}
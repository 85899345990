/**
 * Builds sort string with property and sort direction
 * @param property
 * @param direction
 */
export const sort = (property?: string, direction?: string): string | null => {
  if (property === null || direction === null) {
    return null;
  }
  return `${property}:${direction}`;
};

/**
 * Parses sort string (format: /^[a-z0-9]+:["asc" | "desc"]$/)
 * @param sort
 */
export  const parseSort = (sort?: string): string[] | null => {
  if (sort === null) {
    return null;
  }
  return sort.split(":")
};
import React, { ChangeEvent } from "react";
import { TFunction } from "i18next";
import { CustomerFormFields } from "components/forms/CustomerForm/index";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import { FormikErrors } from "formik";

export interface FamilyDetailsCardProps {
  className?: string;
  t: TFunction
  values: Partial<CustomerFormFields>
  errors: FormikErrors<Partial<CustomerFormFields>>
  hasError: (field: string) => boolean
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void
}

export const AdditionalInformationCard = ({ t, values, errors, hasError, handleChange, className }: FamilyDetailsCardProps) => {
  return (
    <Card className={className}>
      <CardHeader title={t("Additional information")}/>
      <Divider/>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              id="notes"
              name="notes"
              label={t("Notes")}
              value={values.notes || ""}
              autoComplete="new-password"
              error={hasError("notes")}
              helperText={
                hasError("notes") ? errors.notes[0] : null
              }
              onChange={handleChange}
              rows={5}
              multiline
              fullWidth
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
import { AuthHttpClient, HttpClientOptionsDefaults } from "services/HttpClient";
import { FirebaseService } from "services/AuthService";
import { CustomerEditableLogsType, CustomerLog, CustomerLogTypeIcon } from "models/Customer";
import { TableHeader } from "stories/components/tables/TTableHead";
import { TTableRow } from "stories/components/tables/TTableRow";
import TableCell from "@material-ui/core/TableCell";
import React, { useState } from "react";
import { TTable, TTableProps, TTableRowProps } from "stories/components/tables/TTable";
import { useDispatch } from "react-redux";
import { CUSTOMER_LIST_LOAD_ERROR } from "reducers/customer";
import { Theme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { RouteComponentProps, withRouter } from "react-router";
import { useTranslation } from "react-i18next";
import { CustomerLogSort, CustomerRemoteService, CustomerService } from "services/CustomerService";
import moment from "moment/moment";
import IconButton from "@material-ui/core/IconButton";
import { Edit } from "@material-ui/icons";
import Icon from "@material-ui/core/Icon";

const defaultAuthHttpClient = new AuthHttpClient(HttpClientOptionsDefaults, FirebaseService.getInstance());

export interface CustomerLogsTableRowProps extends TTableRowProps<CustomerLog> {
  onEdit: (customer: CustomerLog) => void
}

const CustomerLogsTableRow = ({ row, onEdit }: CustomerLogsTableRowProps) => {
  const date = moment(row.date);

  const onEditClickHandler = () => {
    onEdit && onEdit(row);
  };

  return (
    <TTableRow rowKey={row.id}>
      <TableCell align="center">
        <Icon component={CustomerLogTypeIcon[row.type]}/>
      </TableCell>
      <TableCell>
        {date.isValid() ? date.format("HH:mm:ss DD/MM/YYYY") : "-"}
      </TableCell>
      <TableCell>{row.creatorName}</TableCell>
      <TableCell>
        {row.notes}
      </TableCell>
      <TableCell align="right">
        {CustomerEditableLogsType.filter(type => type === row?.type) &&
        <IconButton onClick={() => onEditClickHandler()}>
          <Edit/>
        </IconButton>
        }
      </TableCell>
    </TTableRow>
  );
};

const CustomersTableComponent = ({ customerId, onEdit, location, history, customerService = new CustomerRemoteService(defaultAuthHttpClient), ...props }: CustomersTableProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isSmallDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const [logs, setLogs] = useState([] as CustomerLog[]);
  const [total, setTotal] = useState(0);

  const headers: TableHeader[] = [
    { id: "type", label: "" },
    { id: "date", label: t("Date"), sortable: true },
    { id: "author", label: t("Author") },
    { id: "notes", label: t("Notes"), hide: isSmallDevice },
    { id: "actions", label: "" }
  ];

  const onLoad = (page: number, rowsPerPage: number, sort?: string): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      if ((total !== 0 && logs.length === total) || logs.length >= (page + 1) * rowsPerPage) {
        resolve();
        return;
      }

      customerService.getCustomerLogs(customerId, page, rowsPerPage, sort as CustomerLogSort)
        .then((pagination) => {
          setLogs((currentLogs) => {
            if (page !== 1) {
              return pagination.data;
            }

            return [...currentLogs, ...pagination.data];
          });
          setTotal(pagination.total);
          resolve();
        })
        .catch((error) => {
          dispatch(CUSTOMER_LIST_LOAD_ERROR(error));
          reject(error);
        });
    });
  };

  const onRefresh = (rowsPerPage: number, sort?: string): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      customerService.getCustomerLogs(customerId, 1, rowsPerPage, sort as CustomerLogSort)
        .then((pagination) => {
          setLogs(pagination.data);
          setTotal(pagination.total);
          resolve();
        })
        .catch((error) => {
          dispatch(CUSTOMER_LIST_LOAD_ERROR(error));
          reject(error);
        });
    });
  };

  return (
    <TTable
      headers={headers}
      data={logs}
      total={total}
      rowTemplate={(props) => <CustomerLogsTableRow onEdit={onEdit} {...props}/>}
      onLoad={onLoad}
      onRefresh={onRefresh}
      onChangePage={onLoad}
      onSort={onRefresh}
      initialSort={CustomerLogSort.DATE_DESC}
      onChangeRowsPerPage={onRefresh}
      {...props} />
  );
};

export interface CustomersTableProps extends Partial<TTableProps>, RouteComponentProps {
  customerId: string
  customerService?: CustomerService
  onEdit: (log: CustomerLog) => void
}

export const CustomerLogsTable = withRouter(CustomersTableComponent);
import * as Remote from "models/Remote";
import { UserCompany } from "models/UserCompany";
import { Customer, CustomerAdapter } from "models/Customer";
import { Pagination } from "models/Pagination";
import { RemoteService } from "services/RemoteService";

export enum CompanySort {
  DEFAULT_DESC = "default:desc",
}

export enum CustomerSort {
  CREATED_DESC = "created:desc",
}

export interface CompanyService {
  getUserCompanies(sort: CompanySort, page?: number, pageSize?: number): Promise<UserCompany[]>

  getCompanyCustomers(companyId: string, page?: number, pageSize?: number, sort?: string): Promise<Pagination<Customer>>
}

export class CompanyRemoteService extends RemoteService implements CompanyService {
  static COMPANIES_PATH: string = "/companies";
  static CUSTOMERS_PATH: (companyId: string) => string = (companyId: string): string => `/companies/${companyId}/customers`;

  getUserCompanies(sort: CompanySort, page: number = 1, pageSize: number = Remote.DEFAULT_PAGE_SIZE): Promise<UserCompany[]> {
    return new Promise<UserCompany[]>((resolve, reject) => {
      this.httpClient.get<Remote.Pagination<Remote.UserCompany>>(CompanyRemoteService.COMPANIES_PATH, {
        params: {
          sort: sort,
          page: page,
          pageSize: pageSize,
        }
      })
        .then((pagination: Remote.Pagination<Remote.UserCompany>) => {
          resolve(pagination.data.map<UserCompany>((company) => ({
            id: company.id,
            displayName: company.display_name,
            default: company.default
          } as UserCompany)));
        })
        .catch((error) => {
          reject(this.transformErrorMessage(error));
        });
    });
  }

  getCompanyCustomers(companyId: string, page?: number, pageSize?: number, sort?: string): Promise<Pagination<Customer>> {
    return new Promise<Pagination<Customer>>((resolve, reject) => {
      this.httpClient.get<Remote.Pagination<Remote.Customer>>(CompanyRemoteService.CUSTOMERS_PATH(companyId), {
        params: {
          sort: sort,
          page: page,
          pageSize: pageSize,
        }
      })
        .then((pagination: Remote.Pagination<Remote.Customer>) => {
          resolve({
            currentPage: pagination.current_page,
            lastPage: pagination.last_page,
            rowsPerPage: pagination.per_page,
            total: pagination.total,
            data: pagination.data.map<Customer>(CustomerAdapter.fromRemote)
          });
        })
        .catch((error) => {
          reject(this.transformErrorMessage(error));
        });
    });
  }
}
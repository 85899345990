import React from "react";
import { TFunction } from "i18next";
import { CustomerFormFields } from "components/forms/CustomerForm/index";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import { FormControl } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import { CustomerMaritalStatus } from "models/Customer";
import { FormikErrors } from "formik";

export interface FamilyDetailsCardProps {
  className?: string;
  t: TFunction
  values: Partial<CustomerFormFields>
  errors: FormikErrors<Partial<CustomerFormFields>>
  hasError: (field: string) => boolean
  handleSelectChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void
}

export const FamilyDetailsCard = ({ t, values, errors, hasError, handleSelectChange, className }: FamilyDetailsCardProps) => {
  return (
    <Card className={className}>
      <CardHeader title={t("Family")}/>
      <Divider/>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <FormControl variant="outlined" error={hasError("maritalStatus")} fullWidth>
              <InputLabel id="marital-status-label">{t("Marital Status")}</InputLabel>
              <Select
                labelId="marital-status-label"
                value={values.maritalStatus || ""}
                onChange={handleSelectChange}
                name="maritalStatus"
                fullWidth
              >
                {Object.values(CustomerMaritalStatus).map((maritalStatus, index) => (
                  <MenuItem key={"marital-status-" + index}
                            value={maritalStatus}>{t("marital_status_" + maritalStatus)}</MenuItem>
                ))}
              </Select>
              {hasError("maritalStatus") &&
              <FormHelperText>{errors.maritalStatus[0]}</FormHelperText>
              }
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl variant="outlined" error={hasError("childsNumber")} fullWidth>
              <InputLabel id="childs-number-label">{t("Children number")}</InputLabel>
              <Select
                labelId="childs-number-label"
                value={values.childsNumber || ""}
                onChange={handleSelectChange}
                name="childsNumber"
                fullWidth
              >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
              </Select>
              {hasError("childsNumber") &&
              <FormHelperText>{errors.childsNumber[0]}</FormHelperText>
              }
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
import { ErrorResponse, ErrorResponseCode, HttpClient } from "services/HttpClient";

export enum RemoteErrorCode {
  UNEXPECTED_ERROR = "remote/unexpected_error",
  VALIDATION_ERROR = "remote/validation_error"
}

export interface ErrorMessage {
  code: string
  message: string
}

export class RemoteService {
  protected readonly httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  protected transformErrorMessage(response: ErrorResponse): ErrorMessage {
    switch (response.code) {
      case ErrorResponseCode.VALIDATION:
        return {
          code: RemoteErrorCode.VALIDATION_ERROR,
          message: response.errors[Object.keys(response.errors)[0]][0] // first error for first field
        };
    }

    return {
      code: RemoteErrorCode.UNEXPECTED_ERROR,
      message: "An unexpected error occurred. Please try again."
    };
  }
}
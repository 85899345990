import React from "react";
import PropTypes from "prop-types";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { makeStyles } from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { LinearProgress } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  loadingCell: {
    padding: theme.spacing(0),
    height: "5px",
    border: "none"
  },
  hide: {
    display: "none"
  }
}));

export const TTableHead = ({ headers, loading, order, orderBy, onSort }: TTableHeadProps) => {
  const classes = useStyles();

  const onSortClickHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onSort && onSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell colSpan={headers.length + 1} className={classes.loadingCell}>
          {loading &&
          <LinearProgress/>
          }
        </TableCell>
      </TableRow>
      <TableRow>
        {headers.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric || false ? "right" : "left"}
            padding={headCell.disablePadding || false ? "none" : "default"}
            sortDirection={(headCell.sortable || false) && orderBy === headCell.id ? order : false}
            className={clsx({
              [classes.hide]: headCell.hide
            })}
          >
            {headCell.sortable ?
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "desc"}
                onClick={onSortClickHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel> :
              headCell.label
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export interface TableHeader {
  id: string
  label: string
  numeric?: boolean
  sortable?: boolean
  disablePadding?: boolean
  hide?: boolean
}

export interface TTableHeadProps {
  onSort: Function
  order: "asc" | "desc" | null
  orderBy: string
  headers: TableHeader[]
  loading: boolean
}

TTableHead.propTypes = {
  onSort: PropTypes.func.isRequired,
  headers: PropTypes.array.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  loading: PropTypes.bool
};
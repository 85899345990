import React from "react";
import { connect, useDispatch } from "react-redux";
import { AlertManager as TAlertManager } from "../stories/components/AlertManager";
import { DISMISS_ALERT } from "../reducers/notification";

const AlertManagerComponent = ({ alert }) => {
  const dispatch = useDispatch();

  const onCloseAlert = () => {
    dispatch(DISMISS_ALERT());
  };

  return (
    <TAlertManager
      alert={alert}
      onClose={onCloseAlert} />
  );
};

const mapStateToProps = (state) => {
  return {
    alert: state.notification.alert
  }
}

export const AlertManager = connect(mapStateToProps)(AlertManagerComponent);
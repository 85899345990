import { CompanyRemoteService, CompanyService } from "services/CompanyService";
import { AuthHttpClient, HttpClientOptionsDefaults } from "services/HttpClient";
import { FirebaseService } from "services/AuthService";
import { Customer } from "models/Customer";
import { TableHeader } from "stories/components/tables/TTableHead";
import { TTableRow } from "stories/components/tables/TTableRow";
import TableCell from "@material-ui/core/TableCell";
import React from "react";
import { TTable, TTableProps, TTableRowProps } from "stories/components/tables/TTable";
import { useDispatch } from "react-redux";
import { CUSTOMER_LIST_LOAD_ERROR, CUSTOMER_LIST_LOADED_SUCCESS } from "reducers/customer";
import { Hidden, Theme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import IconButton from "@material-ui/core/IconButton";
import { ArrowForward, Edit } from "@material-ui/icons";
import { RouteComponentProps, withRouter } from "react-router";
import { useTranslation } from "react-i18next";

const defaultAuthHttpClient = new AuthHttpClient(HttpClientOptionsDefaults, FirebaseService.getInstance());

const CustomerTableRow = ({ row, onView, onEdit }: CustomerTableRowProps) => {
  return (
    <TTableRow rowKey={row.id}>
      <TableCell component="th" scope="row">
        #{row.number}
      </TableCell>
      <TableCell>{row.firstName}</TableCell>
      <TableCell>{row.lastName}</TableCell>
      <Hidden smDown>
        <TableCell>{row.dateOfBirth || "-"}</TableCell>
        <TableCell>{row.email || "-"}</TableCell>
        <TableCell>{row.homePostalCode || "-"}</TableCell>
      </Hidden>
      <TableCell align="right">
        <IconButton onClick={() => onEdit(row)}>
          <Edit/>
        </IconButton>
        <IconButton onClick={() => onView(row)}>
          <ArrowForward/>
        </IconButton>
      </TableCell>
    </TTableRow>
  );
};

export interface CustomerTableRowProps extends TTableRowProps<Customer> {
  onView: (customer: Customer) => void
  onEdit: (customer: Customer) => void
}

const CustomersTableComponent = ({ companyId, customers = [], initialSort, total, location, history, companyService = new CompanyRemoteService(defaultAuthHttpClient), ...props }: CustomersTableProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isSmallDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const headers: TableHeader[] = [
    { id: "number", label: t("Number") },
    { id: "firstName", label: t("First Name") },
    { id: "lastName", label: t("Last Name") },
    { id: "dateOfBirth", label: t("Date of birth"), hide: isSmallDevice },
    { id: "email", label: t("Email"), hide: isSmallDevice },
    { id: "homePostalCode", label: t("Postal Code"), hide: isSmallDevice },
    { id: "actions", label: "" }
  ];

  const onLoad = (page: number, rowsPerPage: number, sort?: string): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      if ((total !== 0 && customers.length === total) || customers.length >= (page + 1) * rowsPerPage) {
        resolve();
        return;
      }

      companyService.getCompanyCustomers(companyId, page, rowsPerPage, sort)
        .then((pagination) => {
          dispatch(CUSTOMER_LIST_LOADED_SUCCESS({
            customers: pagination.data,
            total: pagination.total
          }));
          resolve();
        })
        .catch((error) => {
          dispatch(CUSTOMER_LIST_LOAD_ERROR(error));
          reject(error);
        });
    });
  };

  const onRefresh = (rowsPerPage: number, sort?: string): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      companyService.getCompanyCustomers(companyId, 1, rowsPerPage, sort)
        .then((pagination) => {
          dispatch(CUSTOMER_LIST_LOADED_SUCCESS({
            customers: pagination.data,
            total: pagination.total,
            reset: true
          }));
          resolve();
        })
        .catch((error) => {
          dispatch(CUSTOMER_LIST_LOAD_ERROR(error));
          reject(error);
        });
    });
  };

  const onViewClickHandler = (customer: Customer) => {
    history.push(`/companies/${companyId}/customers/${customer.id}`);
  }

  const onEditClickHandler = (customer: Customer) => {
    history.push(`/companies/${companyId}/customers/${customer.id}/edit`);
  }

  return (
    <TTable
      headers={headers}
      data={customers}
      total={total}
      rowTemplate={(props) => <CustomerTableRow onView={onViewClickHandler} onEdit={onEditClickHandler} {...props}/>}
      onLoad={onLoad}
      onRefresh={onRefresh}
      onChangePage={onLoad}
      onSort={onRefresh}
      initialSort={initialSort}
      onChangeRowsPerPage={onRefresh}
      {...props} />
  );
};

export interface CustomersTableProps extends Partial<TTableProps>, RouteComponentProps {
  customers?: Customer[]
  companyService?: CompanyService
  companyId: string
  initialSort?: string
  total: number
}

export const CustomersTable = withRouter(CustomersTableComponent);
import React from "react";
import PropTypes from "prop-types";
import { Refresh } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  title: {
    flexGrow: 1
  }
}));

export const TTableToolbar = ({ onRefresh }: TTableToolbarProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const onRefreshClickHandler = (event: React.MouseEvent<unknown>) => {
    onRefresh && onRefresh(event);
  };

  return (
    <Toolbar className={classes.root}>
      <div className={classes.title}/>
      {onRefresh &&
      <Tooltip title={t("Refresh list")}>
        <IconButton aria-label="refresh" onClick={onRefreshClickHandler}>
          <Refresh/>
        </IconButton>
      </Tooltip>
      }
    </Toolbar>
  );
};

export interface TTableToolbarProps {
  onRefresh: (event: React.MouseEvent<unknown>) => void
}

TTableToolbar.propTypes = {
  onRefresh: PropTypes.func
};
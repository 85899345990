import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { action } from "@storybook/addon-actions";
import { validate } from "validate.js";
import { Container } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { GoogleIcon } from "../Icons";
import { Email, Facebook } from "@material-ui/icons";
import { ProgressButton } from "../ProgressButton";
import { useSecureState } from "../../../utils/useSecureState";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submitButton: {
    margin: theme.spacing(3, 0, 2)
  },
  divider: {
    margin: theme.spacing(1, 0)
  },
  formFooter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end"
  },
  signUpButtons: {
    "& button": {
      marginTop: theme.spacing(1)
    }
  },
  googleButton: {
    backgroundColor: "#DF4A32",
    "&:hover": {
      backgroundColor: "#c8442d"
    }
  },
  facebookButton: {
    backgroundColor: "#2A598F",
    "&:hover": {
      backgroundColor: "#244c79"
    }
  }
}));

export const TSignInForm = ({ onSignInByEmail, onSignUp, onResetPassword, onGoogleSignIn, onFacebookSignIn }) => {
  let state = {
    isValid: false,
    touched: {},
    errors: {},
    values: {}
  };

  const { t } = useTranslation();
  const [formState, setFormState] = React.useState(state);
  const [loading, setLoading] = useSecureState(false);

  const classes = useStyles();

  const validators = {
    email: {
      presence: { allowEmpty: false, message: t("isRequired", { context: "male", field: t("Email") }) },
      email: true
    },
    password: {
      presence: { allowEmpty: false, message: t("isRequired", { context: "female", field: t("Email") }) }
    }
  };

  React.useEffect(() => {
    const errors = validate(formState.values, validators);

    setFormState((formState) => ({
      ...formState,
      isValid: !errors,
      errors: errors || {}
    }));
  }, [formState.values]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const hasError = (field) => {
    return formState.touched[field] && field in formState.errors;
  };

  const onSignInByEmailHandler = (event) => {
    event.preventDefault();
    setLoading(true);
    new Promise(
      onSignInByEmail(formState.values.email, formState.values.password)
    )
      .then(() => {
        onSignInSuccess();
      })
      .catch(() => {
        onSignInError();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSignInSuccess = () => {
    // TODO: Show success message
  };

  const onSignInError = () => {
    // TODO: Show error message
  };

  const onSignUpHandler = (event) => {
    event.preventDefault();
    onSignUp(formState.values.email);
  };

  const onResetPasswordHandler = (event) => {
    event.preventDefault();
    onResetPassword(formState.values.email);
  };

  const onGoogleSignInHandler = (event) => {
    event.preventDefault();
    onGoogleSignIn();
  };

  const onFacebookSignInHandler = (event) => {
    event.preventDefault();
    onFacebookSignIn();
  };

  return (
    <Container className={classes.root}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon/>
      </Avatar>
      <Typography component="h1" variant="h5">
        {t("Sign in")}
      </Typography>
      <form
        className={classes.form}
        noValidate
        onSubmit={onSignInByEmailHandler}
      >
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label={t("Email")}
          name="email"
          autoComplete="email"
          autoFocus
          error={hasError("email")}
          helperText={hasError("email") ? formState.errors.email[0] : null}
          onChange={handleChange}
          value={formState.values.email || ""}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label={t("Password")}
          type="password"
          id="password"
          autoComplete="current-password"
          error={hasError("password")}
          helperText={
            hasError("password") ? formState.errors.password[0] : null
          }
          onChange={handleChange}
          value={formState.values.password || ""}
        />
        <ProgressButton
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submitButton}
          disabled={!formState.isValid}
          loading={loading}
        >
          {t("Sign in")}
        </ProgressButton>
        <Grid container className={classes.formFooter}>
          <Grid item xs>
            <Link href="#" variant="body2" onClick={onResetPasswordHandler}>
              {t("Forgot password?")}
            </Link>
          </Grid>
        </Grid>
        <Divider className={classes.divider}/>
        <Grid container className={classes.signUpButtons}>
          <Button
            variant="contained"
            fullWidth
            color="primary"
            size="small"
            onClick={onSignUpHandler}
            startIcon={<Email/>}
          >
            {t("Sign up with email")}
          </Button>
          <Button
            variant="contained"
            fullWidth
            color="primary"
            size="small"
            onClick={onGoogleSignInHandler}
            className={classes.googleButton}
            startIcon={<GoogleIcon/>}
          >
            {t("Sign in with Google")}
          </Button>
          <Button
            variant="contained"
            fullWidth
            color="primary"
            size="small"
            onClick={onFacebookSignInHandler}
            className={classes.facebookButton}
            startIcon={<Facebook/>}
          >
            {t("Sign in with Facebook")}
          </Button>
        </Grid>
      </form>
    </Container>
  );
};

TSignInForm.propTypes = {
  onSignUp: PropTypes.func.isRequired,
  onResetPassword: PropTypes.func.isRequired,
  onGoogleSignIn: PropTypes.func.isRequired,
  onFacebookSignIn: PropTypes.func.isRequired,
  onSignInByEmail: PropTypes.func.isRequired
};

TSignInForm.defaultProps = {
  onSignUp: action("onSignUp"),
  onResetPassword: action("onResetPassword"),
  onGoogleSignIn: action("onGoogleSignIn"),
  onFacebookSignIn: action("onFacebookSignIn"),
  onSignInByEmail: () => {
    action("onSignInByEmail");
    return (resolve) => {
      setTimeout(() => {
        resolve();
      }, 1000);
    };
  }
};

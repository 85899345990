import React from "react";
import { TResetPasswordForm } from "../../stories/components/forms/TResetPasswordForm";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AuthErrorCode, FirebaseService } from "../../services/AuthService";
import { RESET_PASSWORD_ERROR, RESET_PASSWORD_SUCCESS } from "../../reducers/session";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const ResetPasswordFormComponent = ({ authService, history, location }) => {
  let code = new URLSearchParams(location.search).get("oobCode");
  let dispatch = useDispatch();
  let { t } = useTranslation();

  const backToForgotPassword = () => {
    history.push("/auth/forgot");
  };

  if (code === "") {
    dispatch(RESET_PASSWORD_ERROR({
      code: AuthErrorCode.INVALID_OR_EXPIRED_CODE,
      message: t("Reset password link is invalid or has expired. Please ask for a new link."),
      autoHide: false
    }));
    backToForgotPassword();
  }

  const onConfirmPassword = (newPassword) => {
    return (resolve, reject) => {
      authService.confirmPasswordReset(code, newPassword)
        .then(() => {
          dispatch(RESET_PASSWORD_SUCCESS({
            message: t("Password reset successfully")
          }));
          onBackToLogin();
          resolve();
        })
        .catch((error) => {
          dispatch(RESET_PASSWORD_ERROR({
            ...error,
            autoHide: false
          }));
          if (error.code === AuthErrorCode.INVALID_OR_EXPIRED_CODE) {
            backToForgotPassword();
          }
          reject(error);
        })
    }
  };

  const onBackToLogin = () => {
    history.push("/auth/login");
  };

  return (
    <TResetPasswordForm
       onConfirmPassword={onConfirmPassword}
       onBackToLogin={onBackToLogin}/>
  );
};

export const ResetPasswordForm = withRouter(ResetPasswordFormComponent);

ResetPasswordForm.propTypes = {
  authService: PropTypes.object.isRequired
}

ResetPasswordForm.defaultProps = {
  authService: FirebaseService.getInstance()
}
import firebase from "firebase";
import { firebaseAnalytics } from "./Firebase";

export interface TrackingEvent {
  name: string
  params?: TrackingEventParams
}

export interface TrackingEventParams {
  item_position?: string
  item_name?: string
  item_id?: string
}

export enum TrackingEventNames {
  SIGN_IN = "sign_in",
  LOGOUT = "logout",
  MENU_SELECTED = "menu_selected",
  COMPANY_SELECTED = "company_selected"
}

export enum TrackingMenuPosition {
  TOP = "top",
  SIDE = "side",
}

export enum TrackingMenus {
  DASHBOARD = "dashboard",
  ACCOUNT_SETTINGS = "account_settings",
  CUSTOMERS = "customers",
}

export interface TrackingService {
  trackEvent(name: string, params?: TrackingEventParams): void
}

export class FirebaseTrackingService implements TrackingService {
  static _instance: FirebaseTrackingService;
  _sdk?: firebase.analytics.Analytics = undefined;

  static getInstance() {
    if (!this._instance) {
      this._instance = new FirebaseTrackingService();
    }
    return this._instance;
  }

  private getSDK() {
    if (!this._sdk) {
      this._sdk = firebaseAnalytics
    }
    return this._sdk;
  }

  trackEvent(name: string, params?: TrackingEventParams): void {
    this.getSDK().logEvent(name, params)
  }
}
import React from "react";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { action } from "@storybook/addon-actions";
import { validate } from "validate.js";
import { Container } from "@material-ui/core";
import { ProgressButton } from "../ProgressButton";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import { useSecureState } from "../../../utils/useSecureState";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submitButton: {
    marginTop: theme.spacing(1)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
}));

export const TResetPasswordForm = ({ onConfirmPassword, onBackToLogin, mounted }) => {
  let state = {
    isValid: false,
    touched: {},
    errors: {},
    values: {}
  };

  const { t } = useTranslation();
  const [formState, setFormState] = React.useState(state);
  const [loading, setLoading] = useSecureState(false);

  const classes = useStyles();

  const validators = {
    password: {
      presence: { allowEmpty: false, message: t("isRequired", { context: "female", field: t("Password") }) }
    }
  };

  React.useEffect(() => {
    const errors = validate(formState.values, validators);

    setFormState((formState) => ({
      ...formState,
      isValid: !errors,
      errors: errors || {}
    }));

  }, [formState.values]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const hasError = (field) => {
    return formState.touched[field] && field in formState.errors;
  };

  const onConfirmPasswordHandler = (event) => {
    event.preventDefault();
    setLoading(true);
    new Promise(
      onConfirmPassword(formState.values.password)
    ).finally(() => {
      setLoading(false);
    });
  };

  const onBackToLoginHandler = (event) => {
    event.preventDefault();
    onBackToLogin();
  };

  return (
    <Container className={classes.root}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        {t("Reset Password")}
      </Typography>
      <form
        className={classes.form}
        noValidate
        onSubmit={onConfirmPasswordHandler}
      >
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="password"
          label={t("New Password")}
          name="password"
          type="password"
          autoFocus
          error={hasError("password")}
          helperText={hasError("password") ? formState.errors.password[0] : null}
          onChange={handleChange}
          value={formState.values.password || ""}
        />
        <ProgressButton
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submitButton}
          disabled={!formState.isValid}
          loading={loading}
        >
          {t("Reset Password")}
        </ProgressButton>
        <Divider className={classes.divider} />
        <Grid container>
          <Button
            variant="contained"
            fullWidth
            color="primary"
            size="small"
            onClick={onBackToLoginHandler}
          >
            {t("Back to login")}
          </Button>
        </Grid>
      </form>
    </Container>
  );
};

TResetPasswordForm.propTypes = {
  onConfirmPassword: PropTypes.func.isRequired,
  onBackToLogin: PropTypes.func.isRequired,
};

TResetPasswordForm.defaultProps = {
  onConfirmPassword: () => {
    action("onConfirmPassword");
    return (resolve) => {
      setTimeout(() => {
        resolve();
      }, 1000);
    };
  },
  onBackToLogin: action("onBackToLogin")
};

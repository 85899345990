import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/styles";
import { lightTheme } from "./themes";
import { AuthProvider } from "./components/AuthProvider";
import { HelmetProvider } from "react-helmet-async";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { I18nextProvider } from "react-i18next";
import i18n from "./utils/i18n";
import "utils/validators";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <CssBaseline/>
      <ThemeProvider theme={lightTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <I18nextProvider i18n={i18n}>
            <HelmetProvider>
              <AuthProvider>
                <App/>
              </AuthProvider>
            </HelmetProvider>
          </I18nextProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

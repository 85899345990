import React, { SetStateAction } from "react";
import { validate } from "validate.js";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  }
}));

export const ValidationForm = ({ formState, setFormState, validators, onSubmit, children, ...props }: ValidationFormProps<any>) => {
  const classes = useStyles();

  React.useEffect(() => {
    const errors = validate(formState.values, validators);

    setFormState((data) => {
      const touched: { [fieldName: string]: boolean } = {};
      for (const fieldName in data.values) {
        touched[fieldName] = data.values.hasOwnProperty(fieldName) &&
          typeof data.values[fieldName] !== "undefined";
      }

      return {
        ...data,
        isValid: !errors,
        errors: errors || {},
        touched: touched
      }
    });


  }, [formState.values]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form className={classes.form} noValidate onSubmit={onSubmit} {...props}>
      {children}
    </form>
  );
};

export interface FormState<ValuesState> {
  isValid: boolean
  touched: { [field: string]: boolean }
  errors: { [field: string]: any[] }
  values: ValuesState
}

export interface ValidationFormProps<ValuesState> {
  validators: { [field: string]: {} }
  children: React.ReactElement | React.ReactElement[]
  formState: FormState<ValuesState>
  setFormState: React.Dispatch<SetStateAction<FormState<ValuesState>>>
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void
  autoComplete?: string
}

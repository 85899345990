import { createAction } from "@reduxjs/toolkit";
import { UserCompany } from "models/UserCompany";
import { LOGOUT_SUCCESS } from "reducers/session";

export interface CompanyState {
  list: UserCompany[]
}

const initialState: CompanyState = {
  list: []
}

export interface CompanyListLoadedAction {
  companies: UserCompany[]
}

interface AlertAction {
  message: String
}

export const COMPANY_LIST_LOADED_SUCCESS = createAction<CompanyListLoadedAction>('company/list_loaded_success');
export const COMPANY_LIST_LOAD_ERROR = createAction<AlertAction>('company/list_loaded_error');

// Use the initialState as a default value
export function companyReducer(state: CompanyState = initialState, action: any) {
  // The reducer normally looks at the action type field to decide what happens
  switch (action.type) {
    case COMPANY_LIST_LOADED_SUCCESS.type:
      const companiesLoadedPayload: CompanyListLoadedAction = action.payload;
      return {
        list: [
          ...state.list,
          ...companiesLoadedPayload.companies
        ]
      }
    case LOGOUT_SUCCESS.type:
      return initialState;
    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state
  }
}
import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { action } from "@storybook/addon-actions";
import { validate } from "validate.js";
import { ProgressButton } from "../ProgressButton";
import Grid from "@material-ui/core/Grid";
import { useSecureState } from "../../../utils/useSecureState";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%" // Fix IE 11 issue.
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end"
  },
}));

export const TProfileForm = ({ onLoad, onSave }) => {
  let state = {
    isValid: false,
    touched: {},
    errors: {},
    values: {}
  };

  const { t } = useTranslation();
  const [formState, setFormState] = React.useState(state);
  const [submitLoading, setSubmitLoading] = useSecureState(false);

  const classes = useStyles();

  const validators = {
    firstName: {
      length: {
        maximum: 75,
        tooLong: t("tooLong", { field: t("First Name") })
      }
    },
    lastName: {
      length: {
        maximum: 75,
        tooLong: t("tooLong", { field: t("Last Name") })
      }
    },
    displayName: {
      presence: { allowEmpty: false, message: t("isRequired", { context: "male", field: t("Display Name") }) }
    }
  };

  React.useEffect(() => {
    if (onLoad) {
      new Promise(onLoad())
        .then((data) => {
          setFormState((formState) => ({
            ...formState,
            values: {
              ...formState.values,
              ...data || {}
            }
          }));
        });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    const errors = validate(formState.values, validators);

    setFormState((formState) => ({
      ...formState,
      isValid: !errors,
      errors: errors || {}
    }));
  }, [formState.values]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const hasError = (field) => {
    return formState.touched[field] && field in formState.errors;
  };

  const onSaveHandler = (event) => {
    event.preventDefault();
    setSubmitLoading(true);
    new Promise(
      onSave({
        firstName: formState.values.firstName || "",
        lastName: formState.values.lastName || "",
        displayName: formState.values.displayName
      }))
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  return (
    <form className={classes.form} noValidate onSubmit={onSaveHandler}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="displayName"
            label={t("Display Name")}
            name="displayName"
            autoComplete={t("Display Name")}
            autoFocus
            error={hasError("displayName")}
            helperText={
              hasError("displayName") ? formState.errors.displayName[0] : null
            }
            onChange={handleChange}
            value={formState.values.displayName || ""}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="firstName"
            label={t("First Name")}
            name="firstName"
            autoComplete={t("First Name")}
            error={hasError("firstName")}
            helperText={
              hasError("firstName") ? formState.errors.firstName[0] : null
            }
            onChange={handleChange}
            value={formState.values.firstName || ""}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="lastName"
            label={t("Last Name")}
            name="lastName"
            autoComplete={t("Last Name")}
            error={hasError("lastName")}
            helperText={
              hasError("lastName") ? formState.errors.lastName[0] : null
            }
            onChange={handleChange}
            value={formState.values.lastName || ""}
          />
        </Grid>
        <Grid item xs={12} className={classes.actions}>
          <ProgressButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submitButton}
            disabled={!formState.isValid}
            loading={submitLoading}
          >
            {t("Save changes")}
          </ProgressButton>
        </Grid>
      </Grid>
    </form>
  );
};

TProfileForm.propTypes = {
  onLoad: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

TProfileForm.defaultProps = {
  onLoad: () => {
    action("onLoad");
    return (resolve) => {
      setTimeout(() => {
        resolve();
      }, 1000);
    };
  },
  onSave: () => {
    action("onSave");
    return (resolve) => {
      setTimeout(() => {
        resolve();
      }, 1000);
    };
  }
};

import React from "react";
import { TDashboardLayout } from "stories/layouts/TDashboardLayout";
import { connect, useDispatch } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { GlobalState } from "store";
import { Home } from "@material-ui/icons";
import { AuthService, FirebaseService, OAuthUser } from "services/AuthService";
import { IconButton } from "@material-ui/core";
import { useDrawerMenus, useTopMenus } from "menus";
import { useTranslation } from "react-i18next";
import { SplashScreenPage } from "pages/SplashScreenPage";

const companyName = process.env.REACT_APP_COMPANY_NAME;

const CompanyName = ({ history }: RouteComponentProps) => {
  const onHomeClickHandler = () => {
    history.push("/");
  };

  return (
    <IconButton
      title={companyName}
      onClick={onHomeClickHandler}>
      <Home/>
    </IconButton>
  );
};

const DashboardLayoutComponent = ({ children, user, companyId, authService = FirebaseService.getInstance(), history }: DashboardLayoutProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const drawerMenus = useDrawerMenus(companyId, dispatch, history, t);
  const CompanyNameWithRouter = withRouter(CompanyName);
  const topMenus = useTopMenus(user?.displayName, dispatch, history, t, authService);

  if (!user) {
    return <SplashScreenPage/>
  }

  return (
    <TDashboardLayout
      companyName={<CompanyNameWithRouter/>}
      drawerMenus={drawerMenus}
      topMenus={topMenus}>
      {children}
    </TDashboardLayout>
  );
};

export interface DashboardLayoutProps extends RouteComponentProps {
  children?: React.ReactElement
  user?: OAuthUser
  authService?: AuthService
  companyId: string
}

const mapStateToProps = (state: GlobalState) => {
  return {
    user: state.session.user,
  };
};

export const DashboardLayout = connect(mapStateToProps)(withRouter(DashboardLayoutComponent));
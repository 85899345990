import { createAction } from "@reduxjs/toolkit";
import { Customer, CustomerLog } from "models/Customer";
import { COMPANY_SELECTED, LOGOUT_SUCCESS } from "reducers/session";
import { CustomerSort } from "services/CompanyService";

export interface CustomerState {
  list: Customer[]
  sort?: CustomerSort
  total: number
}

const initialState: CustomerState = {
  list: [],
  sort: CustomerSort.CREATED_DESC,
  total: 0
};

export interface CustomerListLoadedAction {
  customers: Customer[]
  total: number
  sort?: CustomerSort
  reset?: boolean
}

export interface CustomerAction {
  customer: Customer
  message: string
}

export interface CustomerLogAction {
  log: CustomerLog
  message: string
}

interface AlertAction {
  message: String
}

export const CUSTOMER_LIST_LOADED_SUCCESS = createAction<CustomerListLoadedAction>("customer/list_loaded_success");
export const CUSTOMER_LIST_LOAD_ERROR = createAction<AlertAction>("customer/list_loaded_error");
export const CUSTOMER_CREATED_SUCCESS = createAction<CustomerAction>("customer/created_success");
export const CUSTOMER_CREATION_ERROR = createAction<AlertAction>("customer/creation_error");
export const CUSTOMER_UPDATED_SUCCESS = createAction<CustomerAction>("customer/updated_success");
export const CUSTOMER_UPDATE_ERROR = createAction<AlertAction>("customer/update_error");
export const CUSTOMER_NOT_FOUND = createAction<AlertAction>("customer/not_found");
export const CUSTOMER_LOG_CREATED_SUCCESS = createAction<CustomerLogAction>("customer/log_created_success");
export const CUSTOMER_LOG_CREATE_ERROR = createAction<AlertAction>("customer/log_create_error");

// Use the initialState as a default value
export function customerReducer(state: CustomerState = initialState, action: any) {
  // The reducer normally looks at the action type field to decide what happens
  switch (action.type) {
    case CUSTOMER_LIST_LOADED_SUCCESS.type:
      const customersLoadedPayload: CustomerListLoadedAction = action.payload;
      const customersLoadedList = customersLoadedPayload.reset ? customersLoadedPayload.customers : [
        ...state.list,
        ...customersLoadedPayload.customers
      ];
      return {
        list: customersLoadedList,
        sort: customersLoadedPayload.sort,
        total: customersLoadedPayload.total
      };
    case CUSTOMER_CREATED_SUCCESS.type:
      const customerCreated: CustomerAction = action.payload;
      return {
        list: [
          customerCreated.customer,
          ...state.list
        ],
        total: state.total++
      };
    case CUSTOMER_UPDATED_SUCCESS.type:
      const customerUpdated: CustomerAction = action.payload;
      return {
        list: state.list.map(customer => {
          if (customer.id === customerUpdated.customer?.id) {
            return {...customer, ...customerUpdated.customer};
          }
          return customer
        })
      };
    case COMPANY_SELECTED.type:
      return initialState;
    case LOGOUT_SUCCESS.type:
      return initialState;
    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state;
  }
}
import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";

export const TTopMenu = ({ title, icon, menus = [], onMenuClick }: TTopMenuProps) => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onMenuClickHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
    onMenuClick && onMenuClick(event);
  };

  const onMenuCloseHandler = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const onItemClickHandler = (onItemClick: OnItemCloserClick) => {
    return (event: React.MouseEvent<HTMLLIElement | MouseEvent>) => {
      onItemClick && onItemClick(event, onMenuCloseHandler);
    };
  };

  return (
    <>
      <Tooltip title={title}>
        <IconButton
          aria-label={title}
          aria-haspopup={menus.length > 0}
          onClick={onMenuClickHandler}
          color="inherit"
        >
          {icon}
        </IconButton>
      </Tooltip>
      {menus.length > 0 &&
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={menuOpen}
        onClose={onMenuCloseHandler}
      >
        {menus.map((menu, menuIndex) => (
          <div key={menuIndex}>
            {menu.map((item, itemIndex) => (
              <MenuItem key={itemIndex} onClick={onItemClickHandler(item.onClick)}
                        disabled={!item.onClick}>{item.title}</MenuItem>
            ))}
            {menus.length - 1 !== menuIndex && <Divider/>}
          </div>
        ))}
      </Menu>
      }
    </>
  );
};

export type OnItemClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void

export type OnItemCloserClick = (event: React.MouseEvent<HTMLLIElement | MouseEvent>, close: Function) => void

export interface TTopMenuItem {
  title: string
  onClick?: OnItemCloserClick
}

export interface TTopMenuProps {
  title: string
  icon: React.ReactElement
  onMenuClick?: OnItemClick
  menus?: TTopMenuItem[][]
}
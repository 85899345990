import React from "react";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { action } from "@storybook/addon-actions";
import { validate } from "validate.js";
import { Container } from "@material-ui/core";
import { Create as CreateIcon } from "@material-ui/icons";
import { ProgressButton } from "../ProgressButton";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { useSecureState } from "../../../utils/useSecureState";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  formFooter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end"
  },
  submitButton: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export const TSignUpForm = ({ onSignUp, onAlreadyHaveAccount }) => {
  let state = {
    isValid: false,
    touched: {},
    errors: {},
    values: {}
  };

  const { t } = useTranslation();
  const [formState, setFormState] = React.useState(state);
  const [loading, setLoading] = useSecureState(false);

  const classes = useStyles();

  const validators = {
    firstName: {
      presence: { allowEmpty: false, message: t("isRequired", { context: "male", field: t("First Name") }) }
    },
    lastName: {
      presence: { allowEmpty: false, message: t("isRequired", { context: "male", field: t("Last Name") }) }
    },
    email: {
      presence: { allowEmpty: false, message: t("isRequired", { context: "male", field: t("Email") }) },
      email: true
    },
    password: {
      presence: { allowEmpty: false, message: t("isRequired", { context: "female", field: t("Password") }) }
    }
  };

  React.useEffect(() => {
    const errors = validate(formState.values, validators);

    setFormState((formState) => ({
      ...formState,
      isValid: !errors,
      errors: errors || {}
    }));
  }, [formState.values]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const hasError = (field) => {
    return formState.touched[field] && field in formState.errors;
  };

  const onSignUpHandler = (event) => {
    event.preventDefault();
    setLoading(true);
    new Promise(
      onSignUp({
        firstName: formState.values.firstName,
        lastName: formState.values.lastName,
        email: formState.values.email,
        password: formState.values.password
      }))
      .finally(() => {
        setLoading(false);
      });
  };

  const onAlreadyHaveAccountHandler = (event) => {
    event.preventDefault();
    onAlreadyHaveAccount();
  };

  return (
    <Container className={classes.root}>
      <Avatar className={classes.avatar}>
        <CreateIcon/>
      </Avatar>
      <Typography component="h1" variant="h5">
        {t("Sign up")}
      </Typography>
      <form className={classes.form} noValidate onSubmit={onSignUpHandler}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="firstName"
          label={t("First Name")}
          name="firstName"
          autoComplete="First Name"
          autoFocus
          error={hasError("firstName")}
          helperText={
            hasError("firstName") ? formState.errors.firstName[0] : null
          }
          onChange={handleChange}
          value={formState.values.firstName || ""}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="lastName"
          label={t("Last Name")}
          name="lastName"
          autoComplete="Last Name"
          autoFocus
          error={hasError("lastName")}
          helperText={
            hasError("lastName") ? formState.errors.lastName[0] : null
          }
          onChange={handleChange}
          value={formState.values.lastName || ""}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label={t("Email")}
          name="email"
          autoComplete="email"
          autoFocus
          error={hasError("email")}
          helperText={hasError("email") ? formState.errors.email[0] : null}
          onChange={handleChange}
          value={formState.values.email || ""}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label={t("Password")}
          type="password"
          id="password"
          autoComplete="current-password"
          error={hasError("password")}
          helperText={
            hasError("password") ? formState.errors.password[0] : null
          }
          onChange={handleChange}
          value={formState.values.password || ""}
        />
        <ProgressButton
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submitButton}
          disabled={!formState.isValid}
          loading={loading}
        >
          {t("Sign up")}
        </ProgressButton>
        <Grid container className={classes.formFooter}>
          <Grid item xs>
            <Link
              href="#"
              variant="body2"
              onClick={onAlreadyHaveAccountHandler}
            >
              {t("Already have an account? Sign In")}
            </Link>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

TSignUpForm.propTypes = {
  onSignUp: PropTypes.func.isRequired,
  onAlreadyHaveAccount: PropTypes.func.isRequired
};

TSignUpForm.defaultProps = {
  onSignUp: () => {
    action("onSignUp");
    return (resolve) => {
      setTimeout(() => {
        resolve();
      }, 1000);
    };
  },
  onAlreadyHaveAccount: action("onAlreadyHaveAccount")
};

import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import { loadState, saveState } from "./localStorage";
import rootReducer from "reducers";
/**
 * ./node_modules/@reduxjs/toolkit/dist/redux-toolkit.esm.js
 * Attempted import error: 'enableES5' is not exported from 'immer'.
 * @link: https://github.com/reduxjs/redux-toolkit/issues/476
 */
import { enableES5 } from 'immer';
enableES5();

export default function configureStore() {
  const middlewares = [thunkMiddleware]
  const middlewareEnhancer = applyMiddleware(...middlewares)

  const enhancers = [middlewareEnhancer]
  const composedEnhancers = composeWithDevTools(...enhancers)

  const persistedState = loadState();
  const store = createStore(rootReducer, {...persistedState}, composedEnhancers)
  store.subscribe(() => {
    // TODO: Define a strategy to cache state
    saveState({});
  });

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('../reducers', () => store.replaceReducer(rootReducer))
  }

  return store
}
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Home } from "@material-ui/icons";
import Backdrop from "@material-ui/core/Backdrop";
import { LinearProgress } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    backgroundColor: theme.palette.primary.dark,
  },
  icon: {
    color: theme.palette.primary.contrastText
  },
  progress: {
    width: "35px"
  }
}))

export const SplashScreenPage = () => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open>
      <Grid container alignItems="center" alignContent="center" justify="center" direction="column">
        <Grid item>
          <Home className={classes.icon} fontSize="large" color="inherit" />
        </Grid>
        <Grid item>
          <LinearProgress className={classes.progress} color="secondary" />
        </Grid>
      </Grid>
    </Backdrop>
  );
};
import React from "react";
import { Dispatch } from "redux";
import { History } from "history";
import { AccountCircle, Person } from "@material-ui/icons";
import { MENU_SELECTED } from "reducers/navigation";
import { TrackingMenuPosition, TrackingMenus } from "services/TrackingService";
import { LOGOUT_ERROR, LOGOUT_SUCCESS } from "reducers/session";
import { AuthService } from "services/AuthService";
import { TFunction } from "i18next";

export const useDrawerMenus = (companyId: string, dispatch: Dispatch, history: History, t: TFunction) => [[/*{
  icon: <DashboardIcon/>,
  title: t("Dashboard"),
  onClick: () => {
    dispatch(MENU_SELECTED({
      position: TrackingMenuPosition.SIDE,
      menu: TrackingMenus.DASHBOARD
    }));
    history.push(`/companies/${companyId}/dashboard`)
  }
},*/ {
  icon: <Person/>,
  title: t("Customers"),
  onClick: () => {
    dispatch(MENU_SELECTED({
      position: TrackingMenuPosition.SIDE,
      menu: TrackingMenus.CUSTOMERS
    }));
    history.push(`/companies/${companyId}/customers`)
  }
}]];

export const useTopMenus = (displayName: string, dispatch: Dispatch, history: History, t: TFunction, authService: AuthService) => [{
  icon: <AccountCircle/>,
  title: t("Account"),
  menus: [[{
    title: displayName
  }], [{
    title: t("Settings"),
    onClick: (event: React.MouseEvent<unknown>, closeMenu: CallableFunction) => {
      dispatch(MENU_SELECTED({
        position: TrackingMenuPosition.TOP,
        menu: TrackingMenus.ACCOUNT_SETTINGS
      }));
      history.push("/account/settings");
      closeMenu();
    }
  }, {
    title: t("Logout"),
    onClick: () => {
      authService.logout()
        .then(() => {
          dispatch(LOGOUT_SUCCESS({
            message: t("Logout successfully")
          }));
          history.push("/auth/login");
        })
        .catch((error) => {
          dispatch(LOGOUT_ERROR(error));
        });
    }
  }]]
}];
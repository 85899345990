import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useSecureState } from "utils/useSecureState";
import { TTableToolbar } from "./TTableToolbar";
import { TableHeader, TTableHead } from "./TTableHead";
import { Divider } from "@material-ui/core";
import { parseSort, sort } from "utils/sort";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 350
  }
}));

const defaultRowsPerPage = 10;

export const TTable = ({ headers, data, rowTemplate, rowsPerPage:
                         initialRowsPerPage = defaultRowsPerPage, noResultMessage,
                         onLoad, onChangePage, onChangeRowsPerPage, onSort, initialSort,
                         onRefresh, total }: TTableProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const initialPage = 1;
  const [orderBy, setOrderBy] = useSecureState(initialSort ? parseSort(initialSort)[0] : null);
  const [order, setOrder] = useSecureState(initialSort ? parseSort(initialSort)[1] : null);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = useSecureState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useSecureState(initialRowsPerPage);
  const [loading, setLoading] = useSecureState(false);

  const labelNoResults = noResultMessage || t("No results were found");
  const labelLoading = t("Loading");

  React.useEffect(() => {
    if (onLoad && data.length === 0) {
      setLoading(true);
      onLoad(page, rowsPerPage, sort(orderBy, order))
        .finally(() => {
          setLoading(false);
        });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSortHandler = (event: React.MouseEvent<unknown>, property: string) => {
    const isDesc = orderBy === property && order === "desc";
    const direction = isDesc ? "asc" : "desc";
    setLoading(true);
    onSort(rowsPerPage, sort(property, direction))
      .finally(() => {
        setOrder(direction);
        setOrderBy(property);
        setPage(initialPage);
        setLoading(false);
      });
  };

  const onItemCheckboxClickHandler = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const onChangePageHandler = (event: React.MouseEvent<unknown>, newPage: number) => {
    setLoading(true);
    onChangePage(newPage+1, rowsPerPage)
      .finally(() => {
        setPage(newPage+1);
        setLoading(false);
      });
  };

  const onChangeRowPerPageHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    let rowsPerPage = parseInt(event.target.value, 10);
    onChangeRowsPerPage(rowsPerPage, sort(orderBy, order))
      .finally(() => {
        setRowsPerPage(rowsPerPage);
        setPage(initialPage);
        setLoading(false);
      });
  };

  const onRefreshHandler = () => {
    setLoading(true);
    onRefresh(rowsPerPage, sort(orderBy, order))
      .finally(() => {
        setRowsPerPage(rowsPerPage);
        setPage(initialPage);
        setLoading(false);
      });
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TTableToolbar
          onRefresh={onRefreshHandler}/>
        <Divider/>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TTableHead
              loading={loading}
              headers={headers}
              order={order}
              orderBy={orderBy}
              onSort={onSortHandler}
            />
            <TableBody>
              {data.length > 0 ? data.slice((page-1) * rowsPerPage, (page-1) * rowsPerPage + rowsPerPage)
                .map((row: any, index: number) => {
                  return rowTemplate({
                    row: row,
                    key: index,
                    onItemChecked: onItemCheckboxClickHandler
                  });
                }) : (
                <TableRow style={{ height: 53 }}>
                  <TableCell colSpan={headers.length > 0 ? headers.length : 1}>
                    {!loading ? labelNoResults : labelLoading}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={total || data.length}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage={t("rowsPerPage")}
          labelDisplayedRows={({from, to, count}) => t("displayRows", { from: from, to: to, count: count })}
          page={page-1}
          onChangePage={onChangePageHandler}
          onChangeRowsPerPage={onChangeRowPerPageHandler}
        />
      </Paper>
    </div>
  );
};

export interface TTableProps {
  headers: TableHeader[],
  data: any[],
  total?: number
  rowTemplate: (props: TTableRowProps<any>) => React.ReactElement | React.ReactElement[],
  onLoad: (page: number, rowsPerPage: number, sort?: string) => Promise<void>,
  onChangePage: (page: number, rowsPerPage: number) => Promise<void>,
  onChangeRowsPerPage: (rowsPerPage: number, sort?: string) => Promise<void>,
  onSort: (rowsPerPage: number, sort?: string) => Promise<void>,
  onRefresh: (rowsPerPage: number, sort?: string) => Promise<void>,
  initialSort?: string,
  rowsPerPage?: number,
  noResultMessage?: string
}

export interface TTableRowProps<T> {
  row: T
  key?: number
  onItemChecked?: (event: React.MouseEvent<unknown>, name: string) => void
}
export const loadState = (key: string = "state"): object => {
  if (typeof localStorage === "undefined") {
    console.warn("localStorage not supported");
    return;
  }

  try {
    const serialisedState = JSON.parse(localStorage.getItem(key));
    if (serialisedState === null) {
      return undefined;
    }
    return serialisedState;
  } catch (e) {
    return undefined;
  }
};

export const saveState = (state: object, key: string = "state") => {
  if (typeof localStorage === "undefined") {
    console.warn("localStorage not supported");
    return;
  }

  try {
    const serialisedState = JSON.stringify(state);
    localStorage.setItem(key, serialisedState);
  } catch (e) {
    console.log(`unable to store state: ${e}`);
  }
};
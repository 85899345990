import { User } from "models/User";
import * as Remote from "models/Remote";
import { ErrorMessage, RemoteService } from "services/RemoteService";
import { ErrorResponse, ErrorResponseCode } from "services/HttpClient";

export enum UserErrorCode {
  UNEXPECTED_ERROR = "user/unexpected_error",
  ALREADY_EXISTS = "user/already_exists",
}

export interface UserService {
  signUp(email: string, firstName: string, lastName: string, password: string): Promise<User>

  getProfile(): Promise<User>

  saveProfile(displayName: string, firstName: string, lastName: string): Promise<User>
}

export class UserRemoteService extends RemoteService implements UserService {
  static SIGN_UP_PATH: string = "/signup";
  static PROFILE_PATH = "/profile";

  signUp(email: string, firstName: string, lastName: string, password: string): Promise<User> {
    return new Promise<User>((resolve, reject) => {
      this.httpClient.post<Remote.User>(UserRemoteService.SIGN_UP_PATH, {
        email: email,
        first_name: firstName,
        last_name: lastName,
        display_name: firstName,
        password: password
      } as Remote.UserWithPassword)
        .then((user: Remote.User) => {
          resolve({
            displayName: user.display_name,
            firstName: user.first_name,
            lastName: user.last_name
          } as User);
        })
        .catch((error) => {
          reject(this.transformErrorMessage(error));
        });
    });
  }

  getProfile(): Promise<User> {
    return new Promise<User>((resolve, reject) => {
      this.httpClient.get<Remote.User>(UserRemoteService.PROFILE_PATH)
        .then((user: Remote.User) => {
          resolve({
            displayName: user.display_name,
            firstName: user.first_name,
            lastName: user.last_name
          } as User);
        })
        .catch((error) => {
          reject(this.transformErrorMessage(error));
        });
    });
  }

  saveProfile(displayName: string, firstName: string, lastName: string): Promise<User> {
    return new Promise<User>((resolve, reject) => {
      this.httpClient.put<Remote.User>(UserRemoteService.PROFILE_PATH, {
        first_name: firstName,
        last_name: lastName,
        display_name: displayName
      } as Remote.User)
        .then((user: Remote.User) => {
          resolve({
            displayName: user.display_name,
            firstName: user.first_name,
            lastName: user.last_name
          } as User);
        })
        .catch((error) => {
          reject(this.transformErrorMessage(error));
        });
    });
  }

  protected transformErrorMessage(response: ErrorResponse): ErrorMessage {
    switch (response.code) {
      case ErrorResponseCode.CONFLICT:
        return {
          code: UserErrorCode.ALREADY_EXISTS,
          message: "The email has already been taken."
        };
    }

    return super.transformErrorMessage(response)
  }
}
import React, { ChangeEvent } from "react";
import Grid from "@material-ui/core/Grid";
import { ProgressButton } from "stories/components/ProgressButton";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Toolbar } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { ArrowBack } from "@material-ui/icons";
import moment from "moment/moment";
import * as Countries from "utils/countries";
import { Country } from "utils/countries";
import { DEFAULT_DATE_FORMAT } from "utils/validators";
import { CustomerRemoteService, CustomerService } from "services/CustomerService";
import { AuthHttpClient, HttpClientOptionsDefaults } from "services/HttpClient";
import { FirebaseService } from "services/AuthService";
import { Customer } from "models/Customer";
import { useDispatch, useSelector } from "react-redux";
import {
  CUSTOMER_CREATED_SUCCESS,
  CUSTOMER_CREATION_ERROR,
  CUSTOMER_UPDATE_ERROR,
  CUSTOMER_UPDATED_SUCCESS
} from "reducers/customer";
import { GlobalState } from "store";
import { PhoneNumberUtil } from "google-libphonenumber";
import { useTranslation } from "react-i18next";
import { PersonalDetailsCard } from "components/forms/CustomerForm/components/PersonalDetailsCard";
import { ContactDetailsCard } from "components/forms/CustomerForm/components/ContactDetailsCard";
import { FamilyDetailsCard } from "components/forms/CustomerForm/components/FamilyDetailsCard";
import { AdditionalInformationCard } from "components/forms/CustomerForm/components/AdditionalInformationCard";
import { useFormik } from "formik";
import { match, RouteComponentProps, withRouter } from "react-router";
import { validate } from "validate.js";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main
  },
  actions: {
    paddingTop: theme.spacing(2)
  },
  container: {
    paddingTop: theme.spacing(2)
  },
  hide: {
    display: "none"
  },
  spacer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  cardSpacer: {
    marginTop: theme.spacing(2)
  }
}));

const defaultAuthHttpClient = new AuthHttpClient(HttpClientOptionsDefaults, FirebaseService.getInstance());

const CustomerFormComponent = ({ companyId, history, match, customerService = new CustomerRemoteService(defaultAuthHttpClient) }: CustomerFormProps) => {
  const { t } = useTranslation();

  const validators = {
    firstName: {
      presence: { allowEmpty: false, message: t("isRequired", { context: "male", field: t("First Name") }) },
      length: {
        maximum: 75,
        tooLong: t("tooLong", { field: t("First Name") })
      }
    },
    lastName: {
      presence: { allowEmpty: false, message: t("isRequired", { context: "male", field: t("Last Name") }) },
      length: {
        maximum: 75,
        tooLong: t("tooLong", { field: t("Last Name") })
      }
    },
    fullName: {
      length: {
        maximum: 255,
        tooLong: t("tooLong", { field: t("Full Name") })
      }
    },
    dateOfBirth: {
      datetime: {
        dateOnly: true,
        latest: moment.utc().subtract(18, "years"),
        message: "^" + t("Customer needs to be at least 18 years old")
      }
    },
    email: {
      email: true
    },
    phoneNumber: {
      format: {
        pattern: "[0-9]*",
        message: t("can only contain numbers")
      }
    }
  };

  const customer: Customer | null = useSelector((state: GlobalState) => state.customer.list.find(c => c.id === match.params.customerId));
  let phoneNumber;
  try {
    phoneNumber = PhoneNumberUtil.getInstance().parse(customer?.phoneNumber);
  } catch (e) {
    phoneNumber = null;
  }

  const defaultValues: CustomerFormFields = {
    ...customer,
    phoneCountryCode: phoneNumber ? `+${phoneNumber.getCountryCode().toString()}` : Countries.PORTUGAL.phone,
    country: customer?.country || Countries.PORTUGAL.code,
    phoneNumber: phoneNumber ? phoneNumber.getNationalNumber()?.toString() : ""
  };

  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const formik = useFormik<CustomerFormFields>({
    initialValues: defaultValues,
    validate: (values) => validate(values, validators),
    onSubmit: (values) => onSaveHandler(values)
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    formik.setFieldValue(event.target.name, event.target.type === "checkbox"
      ? event.target.checked : event.target.value);
  };

  const handleDateChange = (fieldName: string) => {
    return (date: moment.Moment, value?: string) => {
      formik.setFieldValue(fieldName, date ? date.format(DEFAULT_DATE_FORMAT) : null);
    };
  };

  const handleAutoCompleteChange = (fieldName: string, map: (value: Country) => string) => {
    return (event: ChangeEvent<{}>, value: Country) => {
      formik.setFieldValue(fieldName,  map(value));
    };
  };

  const handleSelectChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    formik.setFieldValue(event.target.name, event.target.value);
  };

  const hasError = (field: string) => {
    return formik.getFieldMeta(field)?.touched && field in formik.errors;
  };

  const onSaveHandler = (values: CustomerFormFields) => {
    setLoading(true);
    let phoneNumber;
    try {
      phoneNumber = PhoneNumberUtil.getInstance().parse(`${values?.phoneCountryCode}${values?.phoneNumber}`);
    } catch (e) {
      phoneNumber = null;
    }

    const customer = {
      ...values as Customer,
      phoneNumber: phoneNumber ? `+${phoneNumber?.getCountryCode()}${phoneNumber?.getNationalNumber()}` : null
    } as Customer;

    let promise: Promise<void>;
    if (customer.id) {
      promise = customerService.update(customer)
        .then((customer: Customer) => {
          dispatch(CUSTOMER_UPDATED_SUCCESS({
            customer: customer,
            message: t("Customer updated")
          }));
          history.push(`/companies/${companyId}/customers`);
        })
        .catch((error) => {
          dispatch(CUSTOMER_UPDATE_ERROR(error));
        });
    } else {
      promise = customerService.create(companyId, customer)
        .then((customer: Customer) => {
          dispatch(CUSTOMER_CREATED_SUCCESS({
            customer: customer,
            message: t("Customer created")
          }));
          history.push(`/companies/${companyId}/customers`);
        })
        .catch((error) => {
          dispatch(CUSTOMER_CREATION_ERROR(error));
        });
    }

    promise
      .finally(() => {
        setLoading(false);
      });
  };

  const onBackClickHandler = () => {
    history.goBack();
  };

  return (
    <Grid>
      <Toolbar className={classes.toolbar}>
        <IconButton color="inherit" onClick={onBackClickHandler}>
          <ArrowBack/>
        </IconButton>
        <Typography component="h1" variant="h5">
          {t("Create Customer")}
        </Typography>
      </Toolbar>
      <Container className={classes.container}>
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <PersonalDetailsCard
            t={t}
            values={{
              firstName: formik.values.firstName,
              lastName: formik.values.lastName,
              country: formik.values.country,
              dateOfBirth: formik.values.dateOfBirth,
              drivingLicense: formik.values.drivingLicense,
              drivingLicenseExpiryDate: formik.values.drivingLicenseExpiryDate,
            }}
            errors={formik.errors}
            hasError={hasError}
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            handleAutoCompleteChange={handleAutoCompleteChange}/>
          <ContactDetailsCard
            className={classes.cardSpacer}
            t={t}
            values={{
              email: formik.values.email,
              phoneCountryCode: formik.values.phoneCountryCode,
              phoneNumber: formik.values.phoneNumber,
              homeAddress: formik.values.homeAddress,
              homePostalCode: formik.values.homePostalCode,
              workAddress: formik.values.workAddress,
              workPostalCode: formik.values.workPostalCode,
            }}
            errors={formik.errors}
            hasError={hasError}
            handleChange={handleChange}
            handleAutoCompleteChange={handleAutoCompleteChange}/>
          <FamilyDetailsCard
            className={classes.cardSpacer}
            t={t}
            values={{
              maritalStatus: formik.values.maritalStatus,
              childsNumber: formik.values.childsNumber,
            }}
            errors={formik.errors}
            hasError={hasError}
            handleSelectChange={handleSelectChange}/>
          <AdditionalInformationCard
            className={classes.cardSpacer}
            t={t}
            values={{
              notes: formik.values.notes,
            }}
            errors={formik.errors}
            hasError={hasError}
            handleChange={handleChange}/>
          <Grid container justify="flex-end" className={classes.actions}>
            <ProgressButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={!formik.isValid}
              loading={loading}
            >
              {formik.values?.id ? t("Save changes") : t("Save")}
            </ProgressButton>
          </Grid>
        </form>
      </Container>
    </Grid>
  );
};

export interface CustomerFormProps extends RouteComponentProps {
  companyId: string
  customerService?: CustomerService
  match: match<CustomerFormMatch>
}

export interface CustomerFormMatch {
  customerId: string
}

export interface CustomerFormFields extends Customer, Object {
  phoneCountryCode?: string
}

export const CustomerForm = withRouter(CustomerFormComponent);
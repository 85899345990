import * as Remote from "models/Remote";
import { Customer, CustomerAdapter, CustomerLog, CustomerLogAdapter } from "models/Customer";
import { Pagination } from "models/Pagination";
import { RemoteService } from "services/RemoteService";

export enum CustomerErrorCode {
  UNEXPECTED_ERROR = "company/unexpected_error",
}

export enum CustomerLogSort {
  DATE_DESC = "date:desc",
}

export interface CustomerService {
  create(companyId: string, customer: Partial<Customer>): Promise<Customer>

  update(customer: Partial<Customer>): Promise<Customer>

  getCustomerLogs(customerId: string, page?: number, pageSize?: number, sort?: CustomerLogSort): Promise<Pagination<CustomerLog>>

  createLog(customerId: string, log: CustomerLog): Promise<CustomerLog>

  editLog(log: CustomerLog): Promise<CustomerLog>
}

export class CustomerRemoteService extends RemoteService implements CustomerService {
  static CUSTOMERS_PATH: (companyId: string) => string =
    (companyId: string): string => `/companies/${companyId}/customers`;
  static CUSTOMERS_UPDATE_PATH: (companyId: string, customerId: string) => string =
    (companyId: string, customerId: string): string => `/companies/${companyId}/customers/${customerId}`;
  static CUSTOMER_LOGS_PATH: (customerId: string) => string =
    (customerId: string): string => `/customers/${customerId}/logs`;
  static CUSTOMER_LOG_PATH: (customerId: string, logId: string) => string =
    (customerId: string, logId: string): string => `/customers/${customerId}/logs/${logId}`;

  create(companyId: string, customer: Customer): Promise<Customer> {
    return new Promise<Customer>((resolve, reject) => {
      this.httpClient.post<Remote.Customer>(CustomerRemoteService.CUSTOMERS_PATH(companyId), CustomerAdapter.toRemote(customer))
        .then((customer: Remote.Customer) => {
          resolve(CustomerAdapter.fromRemote(customer));
        })
        .catch((error) => {
          reject(this.transformErrorMessage(error));
        });
    });
  }

  update(customer: Customer): Promise<Customer> {
    return new Promise<Customer>((resolve, reject) => {
      this.httpClient.put<Remote.Customer>(CustomerRemoteService.CUSTOMERS_UPDATE_PATH(customer.companyId, customer.id), CustomerAdapter.toRemote(customer))
        .then((customer: Remote.Customer) => {
          resolve(CustomerAdapter.fromRemote(customer));
        })
        .catch((error) => {
          reject(this.transformErrorMessage(error));
        });
    });
  }

  getCustomerLogs(customerId: string, page: number = 1, pageSize: number = Remote.DEFAULT_PAGE_SIZE, sort?: CustomerLogSort): Promise<Pagination<CustomerLog>> {
    return new Promise<Pagination<CustomerLog>>((resolve, reject) => {
      this.httpClient.get<Remote.Pagination<Remote.CustomerLog>>(CustomerRemoteService.CUSTOMER_LOGS_PATH(customerId), {
        params: {
          sort: sort,
          page: page,
          pageSize: pageSize
        }
      })
        .then((pagination: Remote.Pagination<Remote.CustomerLog>) => {
          resolve({
            currentPage: pagination.current_page,
            lastPage: pagination.last_page,
            rowsPerPage: pagination.per_page,
            total: pagination.total,
            data: pagination.data.map<CustomerLog>(CustomerLogAdapter.fromRemote)
          });
        })
        .catch((error) => {
          reject(this.transformErrorMessage(error));
        });
    });
  }

  createLog(customerId: string, log: CustomerLog): Promise<CustomerLog> {
    return new Promise<CustomerLog>((resolve, reject) => {
      this.httpClient.post<Remote.CustomerLog>(CustomerRemoteService.CUSTOMER_LOGS_PATH(customerId), CustomerLogAdapter.toRemote(log))
        .then((log: Remote.CustomerLog) => {
          resolve(CustomerLogAdapter.fromRemote(log));
        })
        .catch((error) => {
          reject(this.transformErrorMessage(error));
        });
    });
  }

  editLog(log: CustomerLog): Promise<CustomerLog> {
    return new Promise<CustomerLog>((resolve, reject) => {
      this.httpClient.put<Remote.CustomerLog>(CustomerRemoteService.CUSTOMER_LOG_PATH(log?.customerId, log?.id), CustomerLogAdapter.toRemote(log))
        .then((log: Remote.CustomerLog) => {
          resolve(CustomerLogAdapter.fromRemote(log));
        })
        .catch((error) => {
          reject(this.transformErrorMessage(error));
        });
    });
  }
}
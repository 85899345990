import React from "react";
import { CentralPanelLayout } from "layouts/CentralPanelLayout";
import { SignInForm } from "components/forms/SignInForm";
import { SignUpForm } from "components/forms/SignUpForm";
import { ForgotPasswordForm } from "components/forms/ForgotPasswordForm";
import { AuthActionForm } from "components/forms/AuthActionForm";
import { DashboardLayout } from "layouts/DashboardLayout";
import { AccountSettings } from "components/AccountSettings";
import { match, Redirect } from "react-router";
import { LandingLayout } from "layouts/LandingLayout";
import { LandingPage } from "pages/LandingPage";
import { CustomersPage } from "pages/CustomersPage";
import { CustomerForm } from "components/forms/CustomerForm";
import * as H from "history";
import { CustomerProfilePage } from "pages/CustomerProfilePage";

interface MatchParams {
  companyId?: string
  customerId?: string
}

export interface RouteProps {
  isLogged: boolean
  location: H.Location
  match: match<MatchParams>
  children?: React.ReactElement
}

export interface LayoutProps {
  match: match<MatchParams>
  children?: React.ReactElement
}

export interface Route {
  layout: (props: LayoutProps) => React.ReactElement
  onBeforeRender?: (props: RouteProps) => React.ReactElement
  path: string
  exact: boolean
  pages: RouteComponent[]
}

export interface RouteComponent {
  title?: string
  path: string
  exact: boolean
  component: (props: RouteProps) => React.ReactElement
}

const routes: Route[] = [
  {
    path: "/auth/:path?",
    exact: true,
    layout: (props: LayoutProps) => <CentralPanelLayout {...props} />,
    onBeforeRender: ({ isLogged, children, location }) => {
      if (isLogged) {
        return <Redirect
          to={{
            pathname: "/",
            state: { from: location }
          }}
        />;
      }

      return children;
    },
    pages: [
      {
        title: "Login",
        path: "/auth/login",
        exact: true,
        component: (props: any) => <SignInForm {...props} />
      },
      {
        title: "Sign up",
        path: "/auth/signup",
        exact: true,
        component: (props: any) => <SignUpForm {...props} />
      },
      {
        title: "Forgot Password",
        path: "/auth/forgot",
        exact: true,
        component: (props: any) => <ForgotPasswordForm {...props} />
      },
      {
        title: "Reset password",
        path: "/auth/action",
        exact: true,
        component: (props: any) => <AuthActionForm {...props} />
      },
      {
        path: "*",
        exact: true,
        component: ({ location }) => (
          <Redirect
            to={{
              pathname: "/auth/login",
              state: { from: location }
            }}
          />
        )
      }
    ]
  }, {
    path: "/companies/:companyId/:path?",
    exact: false,
    layout: ({ match, ...props }: LayoutProps) => <DashboardLayout companyId={match.params.companyId} {...props} />,
    onBeforeRender: ({ isLogged, children, location, match }) => {
      if (!isLogged) {
        return <Redirect
          to={{
            pathname: "/auth/login",
            state: { from: location }
          }}
        />;
      }

      if (!match.params.companyId) {
        return <Redirect
          to={{
            pathname: "/",
            state: { from: location }
          }}
        />;
      }

      return children;
    },
    pages: [
      /*{
        title: "Dashboard",
        path: "/companies/:companyId/dashboard",
        exact: true,
        component: ({ match }) => <Typography variant="subtitle1">// TODO: /{match.params.companyId}</Typography>
      },*/
      {
        title: "Customers",
        path: "/companies/:companyId/customers",
        exact: true,
        component: ({ match }) => <CustomersPage companyId={match.params.companyId}/>
      },
      {
        title: "Create Customer",
        path: "/companies/:companyId/customers/create",
        exact: true,
        component: ({ match }) => <CustomerForm companyId={match.params.companyId}/>
      },
      {
        title: "Customer",
        path: "/companies/:companyId/customers/:customerId",
        exact: true,
        component: ({ match }) => <CustomerProfilePage companyId={match.params.companyId} customerId={match.params.customerId}/>
      },
      {
        title: "Edit Customer",
        path: "/companies/:companyId/customers/:customerId/edit",
        exact: true,
        component: ({ match }) => <CustomerForm companyId={match.params.companyId}/>
      },
      {
        path: "/companies/:companyId/",
        exact: true,
        component: ({ match, location }) => {
          return (
            <Redirect
              to={{
                pathname: `/companies/${match.params.companyId}/customers`,
                state: { from: location }
              }}
            />
          );
        }
      },
      {
        path: "*",
        exact: true,
        component: ({ match, location }) => {
          return (
            <Redirect
              to={{
                pathname: `/companies/${match.params.companyId}/customers`,
                state: { from: location }
              }}
            />
          );
        }
      }
    ]
  }, {
    path: "/",
    exact: false,
    layout: (props: any) => <LandingLayout {...props} />,
    onBeforeRender: ({ isLogged, children, location }) => {
      if (!isLogged) {
        return <Redirect
          to={{
            pathname: "/auth/login",
            state: { from: location }
          }}
        />;
      }

      return children;
    },
    pages: [
      {
        title: "Select company",
        path: "/",
        exact: true,
        component: (props: any) => <LandingPage {...props} />
      },
      /*{
        title: "Create company",
        path: "/company/create",
        exact: true,
        component: (props: any) => <Typography>// TODO: /company/create</Typography>
      },*/
      {
        title: "Account - Settings",
        path: "/account/settings",
        exact: true,
        component: (props: any) => <AccountSettings {...props} />
      },
      {
        path: "*",
        exact: true,
        component: ({ location }) => (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }
    ]
  }];

export default routes;
import * as Remote from "./Remote";
import { Comment, Event, Phone, SvgIconComponent } from "@material-ui/icons";

export interface Customer {
  id?: string;
  number?: bigint;
  createdAt?: string;
  companyId?: string;
  firstName: string;
  lastName: string;
  email: string;
  fullName?: string;
  country?: string;
  dateOfBirth?: string;
  drivingLicense?: string;
  drivingLicenseExpiryDate?: string;
  phoneNumber?: string;
  maritalStatus?: string;
  homeAddress?: string;
  homePostalCode?: string;
  workAddress?: string;
  workPostalCode?: string;
  childsNumber?: number;
  notes?: string;
}

export class CustomerAdapter {
  static fromRemote(customer: Remote.Customer): Customer {
    return {
      id: customer.id,
      companyId: customer.company_id,
      number: customer.number,
      firstName: customer.first_name,
      lastName: customer.last_name,
      email: customer.email,
      country: customer.country,
      fullName: customer.full_name,
      dateOfBirth: customer.date_of_birth,
      maritalStatus: customer.marital_status,
      createdAt: customer.created_at,
      drivingLicense: customer.driving_license,
      drivingLicenseExpiryDate: customer.driving_license_expiry_date,
      phoneNumber: customer.phone_number,
      homeAddress: customer.home_address,
      homePostalCode: customer.home_postal_code,
      workAddress: customer.work_address,
      workPostalCode: customer.work_postal_code,
      childsNumber: customer.childs_number,
      notes: customer.notes
    } as Customer;
  }

  static toRemote(customer: Customer): Remote.Customer {
    return {
      id: customer.id,
      company_id: customer.companyId,
      first_name: customer.firstName,
      last_name: customer.lastName,
      email: customer.email,
      full_name: customer.fullName,
      country: customer.country,
      date_of_birth: customer.dateOfBirth,
      marital_status: customer.maritalStatus,
      created_at: customer.createdAt,
      driving_license: customer.drivingLicense,
      driving_license_expiry_date: customer.drivingLicenseExpiryDate,
      phone_number: customer.phoneNumber,
      home_address: customer.homeAddress,
      home_postal_code: customer.homePostalCode,
      work_address: customer.workAddress,
      work_postal_code: customer.workPostalCode,
      childs_number: customer.childsNumber,
      notes: customer.notes,
    } as Remote.Customer;
  }
}

export enum CustomerLogType {
  NOTE = "note",
  EVENT = "event",
  PHONE_CALL = "phone_call",
}

export enum CustomerMaritalStatus {
  MARITAL_STATUS_SINGLE = "single",
  MARITAL_STATUS_MARRIED = "married",
  MARITAL_STATUS_CIVIL_PARTNERED = "civil_partnered",
  MARITAL_STATUS_DIVORCED = "divorced",
  MARITAL_STATUS_WIDOWED = "widowed",
}

export const CustomerLogTypeIcon: { [key: string]: SvgIconComponent } = {
  [CustomerLogType.EVENT]: Event,
  [CustomerLogType.NOTE]: Comment,
  [CustomerLogType.PHONE_CALL]: Phone
};

export const CustomerEditableLogsType = [
  CustomerLogType.NOTE,
  CustomerLogType.PHONE_CALL
];

export interface CustomerLog {
  id?: string
  customerId?: string
  date?: string
  type: string
  creatorName?: string
  notes: string
}

export class CustomerLogAdapter {
  static fromRemote(customer: Remote.CustomerLog): CustomerLog {
    return {
      id: customer.id,
      date: customer.date,
      type: customer.type,
      creatorName: customer.creator_name,
      notes: customer.notes,
      customerId: customer.customer_id
    } as CustomerLog;
  }

  static toRemote(customer: CustomerLog): Remote.CustomerLog {
    return {
      id: customer.id,
      date: customer.date,
      type: customer.type,
      creator_name: customer.creatorName,
      notes: customer.notes,
      customer_id: customer.customerId
    } as Remote.CustomerLog;
  }
}
import React from "react";
import { AuthService, FirebaseService } from "services/AuthService";
import { connect, useDispatch } from "react-redux";
import { AUTH_STATE_CHANGE_ERROR, AUTH_STATE_CHANGED_SUCCESS } from "reducers/session";
import { GlobalState } from "store";
import { useTranslation } from "react-i18next";

const AuthProviderComponent = ({ isLogged, authService = FirebaseService.getInstance(), children }: AuthProviderProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  React.useEffect(() => {
    let unsubscribe: Function = null;
    authService.onAuthStateChanged(unsubscribe)
      .then((user) => {
        if (user) {
          dispatch(AUTH_STATE_CHANGED_SUCCESS({
            user: user
          }));
        } else if (isLogged) {
          dispatch(AUTH_STATE_CHANGE_ERROR({
            message: t("Session has expired. Please try to login again.")
          }));
        }
      })
      .catch((error) => {
        dispatch(AUTH_STATE_CHANGE_ERROR(error));
      });

    return () => {
      unsubscribe && unsubscribe();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return children;
};

type AuthProviderProps = {
  children: React.ReactElement
  authService?: AuthService
  isLogged: boolean
}

const mapStateToProps = (state: GlobalState) => ({
  isLogged: state.session.isLogged
});

export const AuthProvider = connect(mapStateToProps)(AuthProviderComponent);
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Container } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2, 0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  container: {
    marginTop: theme.spacing(2),
    justifyContent: "center",
    direction: "column",
    alignItems: "center"
  },
  copyright: {
    marginTop: theme.spacing(2)
  }
}));

const Copyright = ({ companyName }) => (
  <Typography variant="body2" color="textSecondary" align="center">
    {"Copyright © " + companyName + " " + new Date().getFullYear() + "."}
  </Typography>
);

export const TCentralPanelLayout = ({ companyName, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container component="main">
        <Grid container className={classes.container}>
          <Grid item xs={12} sm={7} md={5}>
            <Paper className={classes.paper}>{children}</Paper>
          </Grid>
        </Grid>
        <Box className={classes.copyright}>
          <Copyright companyName={companyName} />
        </Box>
      </Container>
    </div>
  );
};

TCentralPanelLayout.propTypes = {
  children: PropTypes.node.isRequired,
  companyName: PropTypes.string.isRequired
};

TCentralPanelLayout.defaultProps = {
  companyName: "Your Company"
};

import React, { useRef } from "react";

/**
 * Secure state which prevents state changes when component is unmounted
 * @param initialValue
 */
export function useSecureState(initialValue) {
  const [value, setValue] = React.useState(initialValue);
  const mounted = useRef(false);

  React.useEffect(() => {
    mounted.current = true;
    return () => mounted.current = false;
  });

  const setValueIfMounted = (value) => {
    if (!mounted.current) {
      return;
    }
    setValue(value);
  };

  return [value, setValueIfMounted];
}
import React from "react";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import PropTypes from "prop-types";
import { action } from "@storybook/addon-actions";

export const AlertNotification = ({ message, severity, autoHide, onClose }) => {
  const [isOpen, setOpen] = React.useState(true);

  const autoHideDuration = 5000;

  const onCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    onClose && onClose();
  };

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={autoHide ? autoHideDuration : null}
      onClose={onCloseHandler}
    >
      <Alert elevation={6} variant="filled" severity={severity} onClose={onCloseHandler}>
        {message}
      </Alert>
    </Snackbar>
  );
};

AlertNotification.propTypes = {
  message: PropTypes.string.isRequired,
  severity: PropTypes.string.isRequired,
  autoHide: PropTypes.bool,
  isOpen: PropTypes.bool
};

AlertNotification.defaultProps = {
  message: "",
  severity: "",
  autoHide: true
};

export const AlertManager = ({ alert, onClose }) => {
  if (alert) {
    return (
      <AlertNotification
        message={alert.message}
        severity={alert.severity || "error"}
        autoHide={!!alert.autoHide}
        onClose={onClose} />
    );
  }

  return (
    <div />
  );
};

AlertManager.propTypes = {
  alert: PropTypes.object,
  onClose: PropTypes.func
};

AlertManager.defaultProps = {
  onClose: () => {
    action("onClose");
  }
};

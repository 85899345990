import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  "pt": {
    translation: {
      // validations
      "isRequired_male": "^{{field}} é necessário",
      "isRequired_female": "^{{field}} é necessária",
      "tooLong": "^{{field}} atingiu máximo de %{count} caracteres",

      "Dashboard": "Principal",
      "Customers": "Clientes",
      "Account": "Conta",
      "Settings": "Configurações",
      "Logout": "Sair",
      "Logout successfully": "Saída com sucesso",
      "Customer needs to be at least 18 years old": "O Cliente tem de ter pelo menos 18 anos",
      "can only contain numbers": "só pode conter números",
      "Personal details": "Dados pessoais",
      "First Name": "Primeiro Nome",
      "Last Name": "Último Nome",
      "Email": "E-mail",
      "Home address": "Morada (casa)",
      "Home Postal Code": "Código postal (casa)",
      "Work address": "Morada (trabalho)",
      "Work Postal Code": "Código postal (trabalho)",
      "Country code": "Código (paīs)",
      "Phone number": "Número de telefone",
      "Contact details": "Contatos",
      "Date of birth": "Data de nascimento",
      "Save changes": "Salvar alterações",
      "Family": "Familia",
      "Marital Status": "Estado civil",
      "Country": "País",
      "Create Customer": "Criar cliente",
      "Customer created": "Cliente criado",
      "Customer": "Cliente",
      "New_male": "Novo",
      "New_female": "Nova",
      "Edit": "Editar",
      "dateOfBirthYears": "{{date}} ({{years}} anos)",
      "Driving license": "Carta de condução",
      "Customer not found": "Cliente não encontrado",
      "Postal Code": "Código postal",
      "New Customer": "Novo Cliente",
      "Reset password link is invalid or has expired. Please ask for a new link.": "O link para recuperar senha é invalido ou expirou. Volte a fazer o pedido para repor senha.",
      "Password reset successfully": "Senha reposta com sucesso",
      "Signed in successfully": "Login efetuado com sucesso",
      "Account created successfully": "Conta criada com sucesso",
      "Personal data has been saved": "Dados pessoais guardados com sucesso",
      "Session has expired. Please try to login again.": "A sua sessão expirou. Tente fazer login novamente.",
      "Your companies": "As tuas empresas",
      "404 Page Not Found": "404 Página não encontrada",
      "The page you were looking for doesn't exist.": "A página que procura não existe",
      "Back to login": "Voltar ao login",
      "Account Settings": "Configurações de conta",
      "Forgot Password": "Recuperar senha",
      "Forgot password?": "Esqueci-me da senha?",
      "Reset Password": "Recuperar Senha",
      "Display Name": "Nome de Exibição",
      "Password": "Senha",
      "New Password": "Nova Senha",
      "Sign in": "Entrar",
      "Sign up": "Criar conta",
      "Already have an account? Sign In": "Você já tem conta? Entrar",
      "Sign up with email": "Criar conta com e-mail",
      "Sign in with Google": "Entrar com o Gmail",
      "Sign in with Facebook": "Entrar com o Facebook",
      "No results were found": "Não foram encontrados resultados",
      "Loading": "A carregar...",
      "rowsPerPage": "Resultados por página",
      "Refresh list": "Atualizar",
      "displayRows": "{{from}}-{{to}} de {{count}}",
      "Number": "Número",
      "Login": "Entrar",
      "Edit Customer": "Editar cliente",
      "Account - Settings": "Conta - Configurações",
      "Create company": "Criar empresa",
      "Select company": "Selecionar empresa",
      "Full Name": "Nome completo",
      "Logs": "Registos",
      "Log": "Registo",
      "Date": "Data",
      "Author": "Autor",
      "Notes": "Notas",
      "Note": "Nota",
      "Cancel": "Cancelar",
      "Save": "Salvar",
      "Edit log": "Editar registo",
      "New log": "Novo registo",
      "Type": "Tipo",
      "log_type_note": "Nota",
      "log_type_phone_call": "Chamada",
      "Phone call": "Chamada",
      "Edit {entity}": "Editar {{entity}}",
      "Add {entity}": "Adicionar {{entity}}",
      "Customer log added": "Registo adicionado",
      "Customer updated": "Cliente atualizado",
      "Children number": "Número de filhos(as)",
      "marital_status_single": "Solteiro(a)",
      "marital_status_married": "Casado(a)",
      "marital_status_civil_partnered": "Uniāo de facto",
      "marital_status_divorced": "Divorciado(a)",
      "marital_status_widowed": "Viúvo(a)",
      "Driving License": "Carta de Condução",
      "Expiry Date": "Data de Expiração",
      "Expired": "Expirado",
      "Valid": "Válido",
      "Additional information": "Informação adicional",
    }
  }
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: resources,
    lng: "pt",
    fallbackLng: "pt",
    debug: process.env.NODE_ENV !== 'production',

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;